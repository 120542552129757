import { UserDevice } from '../types/userDevice';
export const getUserDevice = () => {
    if (typeof window !== 'undefined') {
        const devices = [
            {
                name: UserDevice.WebViewIos,
                test: () => /^iOS/.exec(navigator.userAgent)
            },
            {
                name: UserDevice.WebViewAndroid,
                test: () => /^Android/.exec(navigator.userAgent)
            },
            {
                name: UserDevice.Mobile,
                test: () => window.innerWidth <= 767
            },
            {
                name: UserDevice.Tablet,
                test: () => {
                    const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
                    return isTouchDevice && window.screen.width >= 768 && window.screen.width < 1280;
                }
            },
            {
                name: UserDevice.Desktop,
                test: () => window.innerWidth >= 1024
            }
        ];
        for (const device of devices) {
            if (device.test()) {
                return device.name;
            }
        }
    }
    return UserDevice.Unknown;
};
