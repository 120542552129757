import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Skeleton, SkeletonType } from '@front-core/skeleton';
import { BreakPoints, useBreakpoint } from '@front-core/utils';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import styles from './offersListSkeletonItem.scss';
const OffersListSkeletonItem = () => {
    const media = useAppSelector(state => state.ui.media);
    const breakpoint = useBreakpoint(media === BreakPoints.MOBILE ? BreakPoints.MOBILE : BreakPoints.DESKTOP);
    const isMobile = breakpoint === BreakPoints.MOBILE;
    return _jsxs("div", { className: styles.offersListSkeletonItem, "data-sentry-component": "OffersListSkeletonItem", "data-sentry-source-file": "index.tsx", children: [_jsx(Skeleton, { type: SkeletonType.Rect, height: isMobile ? '128px' : '213px', width: isMobile ? '167.5px' : '236px', className: styles.poster, "data-sentry-element": "Skeleton", "data-sentry-source-file": "index.tsx" }), _jsx(Skeleton, { type: SkeletonType.Rect, width: "120px", height: "16px", className: styles.text, "data-sentry-element": "Skeleton", "data-sentry-source-file": "index.tsx" }), _jsx(Skeleton, { type: SkeletonType.Rect, height: "16px", className: styles.text, "data-sentry-element": "Skeleton", "data-sentry-source-file": "index.tsx" }), _jsx(Skeleton, { type: SkeletonType.Rect, height: "16px", className: styles.text, "data-sentry-element": "Skeleton", "data-sentry-source-file": "index.tsx" })] });
};
export default OffersListSkeletonItem;
