import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CasAuthService } from '@front-core/auth-service';
import { DomclickLayoutSSR } from '@front-core/domclick-layout-ssr';
import { debounce, usePrevious, useBreakpoint, BreakPoints } from '@front-core/utils';
import { setTopAppBarLeftIcon, LeftIconType } from '@rcf/webview-interfaces';
import * as Sentry from '@sentry/react';
import { useRef, useEffect, useMemo } from 'react';
import { Routes, Route, useLocation, useMatch, matchPath, generatePath } from 'react-router-dom';
import NotFoundPage from '@pages/notFound/notFound';
import { NavigationTabs } from '@widgets/navigationTabs';
import { agenciesSlice } from '@entities/agencies';
import { agentsSlice } from '@entities/agents';
import { geoApi } from '@entities/geo';
import { uiSlice } from '@entities/ui';
import { urlSlice } from '@entities/url';
import { userSlice } from '@entities/user';
import { PATH, ROUTES } from '@shared/config/constants';
import { WebViewMetricHashes } from '@shared/config/metrics';
import vars from '@shared/config/vars';
import { getMedia } from '@shared/lib/getMedia';
import { useAppDispatch } from '@shared/lib/hooks/useAppDispatch';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import { sendMetrics } from '@shared/lib/sendMetrics';
import ErrorBoundary from '@shared/ui/errorBoundary';
import Preloader from '@shared/ui/preloader';
import '@shared/styles/base.scss';
import routesConfig from './routesConfig';
const { API_ENV } = vars();
const DEBOUNCE_INTERVAL_IN_MS = 300;
const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
const App = () => {
    const media = useAppSelector(state => state.ui.media);
    const isWebView = useAppSelector(state => { var _a; return (_a = state.ui) === null || _a === void 0 ? void 0 : _a.isWebView; });
    const isNotFoundPage = useAppSelector(state => { var _a; return (_a = state.ui) === null || _a === void 0 ? void 0 : _a.isNotFoundPage; });
    const serverCookies = useAppSelector(state => { var _a; return (_a = state.ui) === null || _a === void 0 ? void 0 : _a.serverCookies; });
    const casId = useAppSelector(state => { var _a; return (_a = state.user) === null || _a === void 0 ? void 0 : _a.casId; });
    const agentCasId = useAppSelector(state => { var _a; return (_a = state.agents.agent.details) === null || _a === void 0 ? void 0 : _a.casId; });
    const companyId = useAppSelector(state => { var _a; return (_a = state.agencies.agency.details) === null || _a === void 0 ? void 0 : _a.id; });
    const geoSlug = useAppSelector(state => { var _a; return (_a = state.geo) === null || _a === void 0 ? void 0 : _a.slug; });
    const dispatch = useAppDispatch();
    const [triggerRegion] = geoApi.useLazyGetRegionQuery();
    const firstRender = useRef(true);
    const { pathname } = useLocation();
    const agentsMatch = useMatch(PATH.AGENTS);
    const agenciesMatch = useMatch(PATH.AGENCIES);
    const currentRoute = useMemo(() => routesConfig.map(route => matchPath(route.path, pathname)).find(route => route), [pathname]);
    const breakpoint = useBreakpoint(media === BreakPoints.DESKTOP ? BreakPoints.DESKTOP : BreakPoints.MOBILE);
    const prevPathname = usePrevious(!SSR ? document.location.toString() : undefined);
    const pathMatch = agentsMatch || agenciesMatch;
    const isMobileOrTabletPortrait = breakpoint === BreakPoints.MOBILE || breakpoint === BreakPoints.TABLET_PORTRAIT;
    const initializeAuthService = async () => {
        const authService = new CasAuthService({
            environment: API_ENV
        });
        try {
            const response = await authService.tryReceiveUserData();
            if ('casId' in response) {
                dispatch(userSlice.logIn(response.casId));
            }
        }
        catch (error) {
            console.info(error);
        }
    };
    const updateRegion = async (guid) => {
        dispatch(agentsSlice.putQueryParams({
            regionId: guid
        }));
        dispatch(agenciesSlice.putQueryParams({
            regionId: guid
        }));
        try {
            const response = await triggerRegion(guid);
            if ((response === null || response === void 0 ? void 0 : response.data) && !SSR && currentRoute) {
                const regionSlug = response.data.result.slug;
                const path = generatePath(currentRoute.pattern.path, {
                    regionSlug,
                    agentCasId,
                    companyId
                });
                history.replaceState({}, '', path);
            }
        }
        catch (error) {
            console.info(error);
        }
    };
    const updateCanonical = () => {
        var _a;
        const canonical = String(window.location.href.split('?')[0]);
        const canonicalUrl = pathMatch ? canonical : canonical.replace(`/${(_a = currentRoute === null || currentRoute === void 0 ? void 0 : currentRoute.params) === null || _a === void 0 ? void 0 : _a.regionSlug}`, '');
        dispatch(urlSlice.setCanonical(canonicalUrl));
    };
    const updateRouteData = () => {
        let params = {};
        if (currentRoute === null || currentRoute === void 0 ? void 0 : currentRoute.params) {
            params = currentRoute.params;
        }
        dispatch(urlSlice.setRouteData({
            params,
            path: {
                pattern: (currentRoute === null || currentRoute === void 0 ? void 0 : currentRoute.pattern.path) || '',
                name: (currentRoute === null || currentRoute === void 0 ? void 0 : currentRoute.pathname) || ''
            }
        }));
    };
    const handleLogOut = () => {
        dispatch(userSlice.logOut());
    };
    const handleLogIn = (userData) => {
        if (casId !== userData.casId) {
            dispatch(userSlice.logIn(userData.casId));
        }
    };
    const handleConstruct = (publicMethods) => {
        if (firstRender.current) {
            firstRender.current = false;
            publicMethods.authService.onReceiveUserData(user => dispatch(userSlice.logIn(user.casId)));
        }
        if (!SSR) {
            window.domclickGlobal.domclickLayout = publicMethods;
        }
    };
    const handleRegionChange = (region) => {
        const guid = (region === null || region === void 0 ? void 0 : region.localityGuid) || (region === null || region === void 0 ? void 0 : region.regionGuid);
        if (guid) {
            updateRegion(guid).then();
        }
    };
    const renderContent = () => _jsx(ErrorBoundary, { "data-sentry-element": "ErrorBoundary", "data-sentry-component": "renderContent", "data-sentry-source-file": "index.tsx", children: _jsxs("main", { children: [isWebView ? _jsx(Preloader, {}) : null, pathMatch && !isNotFoundPage ? _jsx(NavigationTabs, {}) : null, _jsx(SentryRoutes, { "data-sentry-element": "SentryRoutes", "data-sentry-source-file": "index.tsx", children: isNotFoundPage ? _jsx(Route, { path: PATH.NOT_FOUND, element: NotFoundPage() }, ROUTES.NOT_FOUND) : routesConfig.map(route => _jsx(Route, { path: route.path, element: route.element() }, route.key)) })] }) });
    useEffect(() => {
        const currentMediaDevice = getMedia();
        const resizeListener = debounce(DEBOUNCE_INTERVAL_IN_MS, () => dispatch(uiSlice.setMedia(currentMediaDevice)));
        if (isWebView) {
            setTopAppBarLeftIcon(LeftIconType.ARROW_BACK);
            initializeAuthService().then();
        }
        if (!SSR) {
            window.addEventListener('resize', resizeListener);
        }
        return () => {
            if (!SSR) {
                window.removeEventListener('resize', resizeListener);
            }
        };
    }, []);
    useEffect(() => {
        updateRouteData();
        if (isWebView) {
            sendMetrics({
                elementId: WebViewMetricHashes.Navigate,
                eventType: 'page_visit',
                referer: prevPathname || ''
            });
        }
    }, [pathname]);
    useEffect(() => {
        if (!SSR) {
            updateCanonical();
        }
    }, [pathname, geoSlug]);
    if (isWebView) {
        return renderContent();
    }
    return _jsx(DomclickLayoutSSR, { isServer: SSR, environment: API_ENV, onConstruct: handleConstruct, defaultCookies: serverCookies !== null && serverCookies !== void 0 ? serverCookies : undefined, defaultDisplayMode: media, toplineProps: {
            enableChat: true,
            chatConfig: {
                isHiddenButton: isMobileOrTabletPortrait
            }
        }, onChangeRegion: handleRegionChange, onLogin: handleLogIn, onLogout: handleLogOut, "data-sentry-element": "DomclickLayoutSSR", "data-sentry-component": "App", "data-sentry-source-file": "index.tsx", children: renderContent() });
};
export default App;
