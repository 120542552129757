import { baseApi } from '@shared/api/base';
import { AGENCIES_TAG, AGENCIES_REGIONS_TAG, AGENCY_TAG } from '@shared/api/base/tags';
import { transformResponseAgentReview } from '@shared/lib/transform/responseAgentsReview';
import { ApiUrls } from '../config/apiUrls';
const LimitConfig = {
    Reviews: 10,
    Agencies: 10
};
export const agenciesApi = baseApi.injectEndpoints({
    endpoints: builder => ({
        getAgencies: builder.query({
            query: params => ({
                url: ApiUrls.Agencies,
                params: {
                    limit: LimitConfig.Agencies,
                    ...params
                }
            }),
            providesTags: response => response
                ? response.result.map(({ id }) => ({ type: AGENCIES_TAG, id }))
                : [AGENCIES_TAG]
        }),
        getAgenciesRegions: builder.query({
            query: params => ({
                url: ApiUrls.AgenciesRegions,
                params
            }),
            providesTags: [AGENCIES_REGIONS_TAG]
        }),
        getAgency: builder.query({
            query: ({ agencyId, agencySlug, regionId }) => ({
                url: ApiUrls.Agency.replace(':companyId', agencyId || agencySlug),
                providesTags: (response) => response
                    ? [{ type: AGENCY_TAG, id: response.result.id }, AGENCY_TAG]
                    : [AGENCY_TAG],
                params: regionId ? { regionId } : {}
            })
        }),
        getAgencyReviews: builder.query({
            query: ({ companyId, ...params }) => ({
                url: ApiUrls.AgenciesReviews.replace(':companyId', companyId),
                params: {
                    offset: 0,
                    limit: LimitConfig.Reviews,
                    ...params
                }
            }),
            transformResponse: transformResponseAgentReview
        }),
        getAgenciesAgents: builder.query({
            query: params => ({
                url: ApiUrls.Agents,
                params
            })
        }),
        getAgenciesAgentsSort: builder.query({
            query: params => ({
                url: ApiUrls.AgentsFilters,
                params
            })
        }),
        getAgenciesOffices: builder.query({
            query: ({ companyId, ...params }) => ({
                url: ApiUrls.AgencyOffices.replace(':companyId', String(companyId)),
                params
            })
        }),
        getAgenciesSubstitutePhone: builder.query({
            query: ({ companyId, ...params }) => ({
                url: ApiUrls.AgenciesSubstitutePhone.replace(':companyId', String(companyId)),
                params
            })
        })
    })
});
export const { endpoints, useGetAgenciesQuery, useLazyGetAgencyQuery, useLazyGetAgenciesAgentsSortQuery, useLazyGetAgencyReviewsQuery, useLazyGetAgenciesRegionsQuery, useLazyGetAgenciesOfficesQuery, useLazyGetAgenciesAgentsQuery, useLazyGetAgenciesSubstitutePhoneQuery } = agenciesApi;
