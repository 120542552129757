import { baseApi } from '@shared/api/base';
import { AGENTS_TAG, AGENTS_REGIONS_TAG, AGENTS_REVIEWS_TAG, AGENT_TAG } from '@shared/api/base/tags';
import { transformResponseAgentReview } from '@shared/lib/transform/responseAgentsReview';
import { AgentReviewOrder } from '../config/agentReviewsOrder';
import { ApiUrls } from '../config/apiUrls';
const LimitConfig = {
    Reviews: 10,
    Agents: 10
};
export const agentsApi = baseApi.injectEndpoints({
    endpoints: builder => ({
        getAgents: builder.query({
            query: params => ({
                url: ApiUrls.Agents,
                params: {
                    limit: LimitConfig.Agents,
                    ...params
                }
            }),
            providesTags: response => response
                ? response.result.map(agent => ({ type: AGENTS_TAG, id: agent.casId }))
                : [AGENTS_TAG]
        }),
        getAgentsCount: builder.query({
            query: params => ({
                url: ApiUrls.AgentsCount,
                params
            })
        }),
        getAgentsSort: builder.query({
            query: params => ({
                url: ApiUrls.AgentsFilters,
                params
            })
        }),
        getAgent: builder.query({
            query: ({ agentCasId, ...params }) => ({
                url: ApiUrls.Agent.replace(':agentCasId', agentCasId),
                params
            }),
            providesTags: response => response
                ? [{ type: AGENT_TAG, id: response.result.casId }, AGENT_TAG]
                : [AGENT_TAG]
        }),
        getAgentRegions: builder.query({
            query: params => ({
                url: ApiUrls.AgentRegions,
                params
            }),
            providesTags: [AGENTS_REGIONS_TAG]
        }),
        getAgentChatOptions: builder.query({
            query: (agentCasId) => ({
                url: ApiUrls.AgentChat.replace(':agentCasId', String(agentCasId)),
                method: 'GET'
            })
        }),
        getAgentReviews: builder.query({
            query: ({ agentCasId, ...params }) => ({
                url: ApiUrls.AgentReviews.replace(':agentCasId', String(agentCasId)),
                params: {
                    limit: LimitConfig.Reviews,
                    order: AgentReviewOrder.Helpful,
                    ...params
                }
            }),
            transformResponse: transformResponseAgentReview,
            providesTags: response => response
                ? response.result.map(review => ({ type: AGENTS_REVIEWS_TAG, id: review.id }))
                : [AGENTS_REVIEWS_TAG]
        }),
        createAgentReview: builder.mutation({
            query: ({ agentCasId }) => ({
                url: ApiUrls.AgentReviews.replace(':agentCasId', String(agentCasId)),
                method: 'POST'
            })
        }),
        getAgentReviewsMe: builder.query({
            query: ({ agentCasId }) => ({
                url: ApiUrls.AgentReviewsMe.replace(':agentCasId', String(agentCasId))
            }),
            transformResponse: transformResponseAgentReview
        }),
        getDealsStats: builder.query({
            query: (agentCasId) => ({
                url: ApiUrls.AgentDealsStats.replace(':agentCasId', agentCasId)
            })
        }),
        getAgentsSubstitutePhone: builder.query({
            query: ({ agentCasId, ...params }) => ({
                url: ApiUrls.AgentsSubstitutePhone.replace(':agentCasId', String(agentCasId)),
                params
            })
        })
    })
});
export const { endpoints, useGetAgentsSortQuery, useLazyGetAgentsCountQuery, useLazyGetAgentQuery, useLazyGetAgentsQuery, useLazyGetAgentRegionsQuery, useLazyGetAgentChatOptionsQuery, useLazyGetAgentReviewsQuery, useCreateAgentReviewMutation, useLazyGetDealsStatsQuery, useLazyGetAgentsSubstitutePhoneQuery, useLazyGetAgentReviewsMeQuery } = agentsApi;
