import { createSlice } from '@reduxjs/toolkit';
import { csiApi } from './api';
const initialState = {
    survey: {
        agent: null,
        agents: null,
        agency: null,
        agencies: null
    }
};
export const csiSlice = createSlice({
    name: 'csi',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addMatcher(csiApi.endpoints.createAgentSurvey.matchFulfilled, (state, { payload }) => {
            state.survey.agent = payload.result;
        });
        builder.addMatcher(csiApi.endpoints.createAgentsSurvey.matchFulfilled, (state, { payload }) => {
            state.survey.agents = payload.result;
        });
        builder.addMatcher(csiApi.endpoints.createAgencySurvey.matchFulfilled, (state, { payload }) => {
            state.survey.agency = payload.result;
        });
        builder.addMatcher(csiApi.endpoints.createAgenciesSurvey.matchFulfilled, (state, { payload }) => {
            state.survey.agencies = payload.result;
        });
    }
});
export const { reducer } = csiSlice;
