import { baseApi } from '@shared/api/base';
import { ApiUrls } from '../config/apiUrls';
export const reviewsApi = baseApi.injectEndpoints({
    endpoints: builder => ({
        updateReviewsAnswer: builder.mutation({
            query: ({ reviewId, message }) => ({
                method: 'PUT',
                url: ApiUrls.Answer.replace(':reviewId', String(reviewId)),
                body: { message }
            })
        }),
        deleteReviewsAnswer: builder.mutation({
            query: reviewId => ({
                method: 'DELETE',
                url: ApiUrls.Answer.replace(':reviewId', String(reviewId))
            })
        }),
        updateReview: builder.mutation({
            query: ({ reviewId, message, rating }) => ({
                method: 'PUT',
                url: ApiUrls.Review.replace(':reviewId', String(reviewId)),
                body: {
                    message,
                    rating
                }
            })
        }),
        deleteReview: builder.mutation({
            query: reviewId => ({
                method: 'DELETE',
                url: ApiUrls.Review.replace(':reviewId', String(reviewId))
            })
        })
    })
});
export const { useUpdateReviewsAnswerMutation, useDeleteReviewsAnswerMutation, useUpdateReviewMutation, useDeleteReviewMutation } = reviewsApi;
