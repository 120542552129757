import { createSlice, createSelector } from '@reduxjs/toolkit';
import { UrlHelper } from '@shared/lib/helpers/url';
import { mapQueryParams } from '@shared/lib/mapQueryParams';
import { AgencyAgentsReviewOrder } from '../config/agentsReviewsOrder';
import { AgencyAgentsReviewsPeriod } from '../config/agentsReviewsPeriod';
import { agenciesDefaultQueryParams } from '../config/defaultQueryParams';
import { agenciesApi } from './api';
const initialState = {
    agency: {
        agents: {
            sort: {
                order: {}
            },
            list: [],
            pagination: {},
            searchParams: {}
        },
        offices: {
            list: [],
            pagination: {}
        },
        reviews: {
            list: [],
            pagination: {},
            queryParams: {
                companyId: '',
                offset: 0,
                order: AgencyAgentsReviewOrder.Helpful,
                period: AgencyAgentsReviewsPeriod.All,
                limit: 10
            },
            isPending: false
        },
        details: {},
        isUpdatePage: false
    },
    list: [],
    pagination: {},
    isPendingList: false,
    isRegionChangedEmptyList: false,
    queryParams: agenciesDefaultQueryParams,
    regions: []
};
export const agenciesSlice = createSlice({
    name: 'agencies',
    initialState,
    selectors: {
        selectPreviewReviewsList: createSelector(state => state.agency.reviews.list, (values) => values.slice(0, 3))
    },
    reducers: {
        setQueryParams(state, { payload }) {
            var _a;
            state.queryParams = {
                ...state.queryParams,
                ...payload
            };
            if (!SSR) {
                const params = new URLSearchParams(window.location.search);
                const mappedParams = mapQueryParams(payload, ((_a = state.pagination) === null || _a === void 0 ? void 0 : _a.limit) || 10);
                Object.keys(mappedParams).forEach(key => {
                    if (params.has(key) && mappedParams[key]) {
                        params.set(key, String(mappedParams[key]));
                    }
                    if (!params.has(key) && mappedParams[key]) {
                        params.append(key, String(mappedParams[key]));
                    }
                    if (params.has(key) && !mappedParams[key]) {
                        params.delete(key);
                    }
                });
                history.replaceState({}, '', UrlHelper.getLocationPathnameWithParams(params));
                state.queryParams = {
                    ...state.queryParams,
                    ...payload
                };
            }
        },
        putQueryParams(state, { payload }) {
            if (!SSR) {
                history.replaceState({}, '', window.location.pathname);
            }
            state.queryParams = {
                regionId: initialState.queryParams.regionId,
                order: initialState.queryParams.order,
                offset: initialState.queryParams.offset,
                dealType: initialState.queryParams.dealType,
                limit: initialState.queryParams.limit,
                search: initialState.queryParams.search,
                ...payload
            };
        },
        resetQueryParams(state) {
            if (!SSR) {
                history.replaceState({}, '', window.location.pathname);
            }
            state.queryParams = {
                ...state.queryParams,
                order: initialState.queryParams.order,
                dealType: initialState.queryParams.dealType,
                offset: initialState.queryParams.offset,
                limit: initialState.queryParams.limit,
                search: initialState.queryParams.search
            };
        },
        setIsRegionChangedEmptyList(state, { payload }) {
            state.isRegionChangedEmptyList = payload;
        },
        setAgentsSearchParams(state, { payload }) {
            state.agency.agents.searchParams = {
                ...state.agency.agents.searchParams,
                ...payload
            };
        },
        setIsAgencyUpdatePage(state, { payload }) {
            state.agency.isUpdatePage = payload;
        },
        setAgencyReviewsQueryParams(state, { payload }) {
            state.agency.reviews.queryParams = {
                ...state.agency.reviews.queryParams,
                ...payload
            };
        }
    },
    extraReducers: builder => {
        builder.addMatcher(agenciesApi.endpoints.getAgencies.matchFulfilled, (state, { payload }) => {
            state.list = payload.result;
            state.pagination = payload.pagination;
            state.isPendingList = false;
        });
        builder.addMatcher(agenciesApi.endpoints.getAgencies.matchPending, state => {
            state.isPendingList = true;
        });
        builder.addMatcher(agenciesApi.endpoints.getAgencies.matchRejected, state => {
            state.isPendingList = false;
        });
        builder.addMatcher(agenciesApi.endpoints.getAgenciesRegions.matchFulfilled, (state, { payload }) => {
            state.regions = payload.result;
        });
        builder.addMatcher(agenciesApi.endpoints.getAgenciesAgents.matchFulfilled, (state, { payload }) => {
            state.agency = {
                ...state.agency,
                agents: {
                    ...state.agency.agents,
                    list: payload.result,
                    pagination: payload.pagination
                }
            };
        });
        builder.addMatcher(agenciesApi.endpoints.getAgenciesAgentsSort.matchFulfilled, (state, { payload }) => {
            if (payload.result) {
                state.agency.agents.sort = payload.result;
            }
        });
        builder.addMatcher(agenciesApi.endpoints.getAgency.matchFulfilled, (state, { payload }) => {
            state.agency.details = payload.result;
        });
        builder.addMatcher(agenciesApi.endpoints.getAgencyReviews.matchPending, state => {
            state.agency.reviews.isPending = true;
        });
        builder.addMatcher(agenciesApi.endpoints.getAgencyReviews.matchFulfilled, (state, { payload }) => {
            var _a;
            const reviews = state.agency.reviews.list.length && ((_a = payload.pagination) === null || _a === void 0 ? void 0 : _a.offset) ? state.agency.reviews.list : [];
            state.agency.reviews.list = [...reviews, ...payload.result];
            state.agency.reviews.pagination = payload.pagination;
            state.agency.reviews.isPending = false;
        });
        builder.addMatcher(agenciesApi.endpoints.getAgencyReviews.matchRejected, state => {
            state.agency.reviews.isPending = false;
        });
        builder.addMatcher(agenciesApi.endpoints.getAgenciesOffices.matchFulfilled, (state, { payload }) => {
            state.agency.offices.list = payload.result;
            state.agency.offices.pagination = payload.pagination;
        });
    }
});
export const { reducer, selectors: { selectPreviewReviewsList }, actions: { setQueryParams, putQueryParams, resetQueryParams, setIsRegionChangedEmptyList, setIsAgencyUpdatePage, setAgentsSearchParams, setAgencyReviewsQueryParams } } = agenciesSlice;
