import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AvatarSize, AvatarIntent } from '@front-core/avatar';
import { Button, Size, Intent } from '@front-core/button';
import { LabelLargeMedium, BodyStandardRegular } from '@front-core/typography';
import { clsx } from 'clsx';
import ClampedText from '@shared/ui/clampedText';
import LayoutAvatar from '@shared/ui/layout/avatar';
import LayoutRating from '@shared/ui/layout/rating';
import { ReviewsScope } from '../../../../config/scope';
import styles from './reviewsCardReview.scss';
import ReviewSubtitle from './subTitle';
const DEFAULT_AUTHOR = 'Участник сделки';
const ReviewsCardReview = ({ scope, review, isPreview, onClick, reviewEditFeature, answerToReviewFeature }) => {
    var _a, _b, _c;
    const hasMessage = (_a = review.message) === null || _a === void 0 ? void 0 : _a.length;
    const hasDate = Boolean(review.dealIssuedAt) || Boolean(review.createdAt);
    const handleClick = () => {
        if (onClick) {
            onClick(review.id);
        }
    };
    if (!review) {
        return null;
    }
    return _jsxs("div", { className: styles.container, "data-sentry-component": "ReviewsCardReview", "data-sentry-source-file": "index.tsx", children: [_jsx("div", { className: styles.avatar, children: _jsx(LayoutAvatar, { intent: AvatarIntent.Secondary, size: AvatarSize.Small, name: ((_b = review.author) === null || _b === void 0 ? void 0 : _b.name) || review.username || DEFAULT_AUTHOR, "data-sentry-element": "LayoutAvatar", "data-sentry-source-file": "index.tsx" }) }), _jsxs("div", { className: styles.header, children: [_jsxs("div", { className: styles.title, children: [_jsx(LabelLargeMedium, { tag: "strong", wrapper: false, className: styles.titleText, "data-sentry-element": "LabelLargeMedium", "data-sentry-source-file": "index.tsx", children: ((_c = review.author) === null || _c === void 0 ? void 0 : _c.name) || review.username || DEFAULT_AUTHOR }), _jsx(LayoutRating, { rating: review.rating, "data-sentry-element": "LayoutRating", "data-sentry-source-file": "index.tsx" })] }), hasDate ? _jsx(ReviewSubtitle, { isAgentScope: scope === ReviewsScope.Agent, createdAt: review.createdAt, dealIssuedAt: review.dealIssuedAt, agent: review.agent, className: styles.subTitle }) : null] }), isPreview && hasMessage ? _jsxs("div", { className: styles.preview, children: [_jsx(BodyStandardRegular, { tag: "p", wrapper: false, className: clsx(styles.description, {
                            [styles.isPreview]: isPreview
                        }), children: review.message }), (review === null || review === void 0 ? void 0 : review.answer) ? _jsx(Button, { text: "\u041F\u043E\u043A\u0430\u0437\u0430\u0442\u044C \u043E\u0442\u0432\u0435\u0442 \u0440\u0438\u0435\u043B\u0442\u043E\u0440\u0430", size: Size.Small, onClick: handleClick, intent: Intent.TransparentSecondary }) : null] }) : null, hasMessage && !isPreview ? _jsx(ClampedText, { className: styles.description, text: review.message, buttonText: "\u041F\u043E\u043A\u0430\u0437\u0430\u0442\u044C \u043F\u043E\u043B\u043D\u043E\u0441\u0442\u044C\u044E" }) : null, reviewEditFeature ? _jsx("div", { className: styles.reviewButtons, children: reviewEditFeature }) : null, answerToReviewFeature ? _jsx("div", { className: styles.answerToReviewButton, children: answerToReviewFeature }) : null] });
};
export default ReviewsCardReview;
