import { StringHelper } from '@shared/lib/helpers/string';
import { AgentTypesLabel, AgentTypes } from '../config/agentTypes';
export const getAgentType = (agent) => {
    if (agent.type === AgentTypes.Agent && agent.company) {
        const extraCompanies = agent.company.extraCompaniesCount > 0
            ? ` и ещё ${agent.company.extraCompaniesCount}\u00A0${StringHelper.getPluralForm(agent.company.extraCompaniesCount, ['компания', 'компании', 'компаний'], false)}`
            : '';
        return `${agent.company.name}${extraCompanies}`;
    }
    return AgentTypesLabel[agent.type];
};
