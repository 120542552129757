export var AnswerToReviewDropdownItemValues;
(function (AnswerToReviewDropdownItemValues) {
    AnswerToReviewDropdownItemValues["Edit"] = "edit";
    AnswerToReviewDropdownItemValues["Delete"] = "delete";
    AnswerToReviewDropdownItemValues["Default"] = "default";
})(AnswerToReviewDropdownItemValues || (AnswerToReviewDropdownItemValues = {}));
export const AnswerToReviewDropdownItems = [
    { title: 'Изменить', value: AnswerToReviewDropdownItemValues.Edit },
    { title: 'Удалить', value: AnswerToReviewDropdownItemValues.Delete }
];
