import ENV from '@rcf/envs';
import vars from '@shared/config/vars';
import { ApiNames } from './names';
const { PTS_AGENCIES_API_URL } = vars();
export const apiUrls = {
    [ApiNames.BASE]: {
        [ENV.QA]: SSR && PTS_AGENCIES_API_URL ? `${PTS_AGENCIES_API_URL}/api` : 'https://api.qa-dc.ru/pts/agencies',
        [ENV.STAGE]: SSR && PTS_AGENCIES_API_URL ? `${PTS_AGENCIES_API_URL}/api` : 'https://api.stage-dc.ru/pts/agencies',
        [ENV.PROD]: SSR && PTS_AGENCIES_API_URL ? `${PTS_AGENCIES_API_URL}/api` : 'https://api.domclick.ru/pts/agencies'
    },
    [ApiNames.PPS]: {
        [ENV.QA]: 'https://api.qa-dc.ru/pps/partners-stats',
        [ENV.STAGE]: 'https://api.stage-dc.ru/pps/partners-stats',
        [ENV.PROD]: 'https://api.domclick.ru/pps/partners-stats'
    },
    [ApiNames.RESEARCH]: {
        [ENV.QA]: 'https://tel.qa-dc.ru/research',
        [ENV.STAGE]: 'https://stage-tel.domclick.ru/research',
        [ENV.PROD]: 'https://tel.domclick.ru/research'
    },
    [ApiNames.LOCAL]: {
        [ENV.QA]: 'http://localhost:3004',
        [ENV.STAGE]: 'http://localhost:3004',
        [ENV.PROD]: 'http://localhost:3004'
    },
    [ApiNames.IMG]: {
        [ENV.QA]: 'https://img.qa-dc.ru',
        [ENV.STAGE]: 'https://img.stage-dc.ru',
        [ENV.PROD]: 'https://img.dmclk.ru'
    }
};
