import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Paper } from '@front-core/box';
import { Button, Intent, Size } from '@front-core/button';
import { Fragment, useEffect, useState } from 'react';
import DealsListHeader from './components/header';
import DealsListItem from './components/item';
import DealsListSkeleton from './components/skeleton';
import styles from './dealsList.scss';
const DealsList = ({ deals, isLoading, pagination, buttonRef, onLoadMore }) => {
    const [isShowMoreDeals, setIsShowMoreDeals] = useState(false);
    useEffect(() => {
        if (isLoading && deals.length !== 0) {
            setTimeout(() => {
                var _a;
                (_a = buttonRef.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView({
                    block: 'center',
                    behavior: 'smooth'
                });
            }, 300);
        }
    }, [isLoading]);
    useEffect(() => {
        if (pagination) {
            setIsShowMoreDeals(pagination.offset + pagination.limit < pagination.total);
        }
    }, [pagination]);
    return _jsxs(Paper, { tag: "section", className: styles.container, "data-sentry-element": "Paper", "data-sentry-component": "DealsList", "data-sentry-source-file": "index.tsx", children: [isLoading && deals.length === 0 ? _jsx(DealsListSkeleton, {}) : _jsxs(Fragment, { children: [pagination ? _jsx(DealsListHeader, { count: deals.length }) : null, _jsx("div", { className: styles.list, children: deals.map(deal => _jsx(DealsListItem, { deal: deal }, deal.id)) })] }), isShowMoreDeals ? _jsx(Button, { text: "\u041F\u043E\u043A\u0430\u0437\u0430\u0442\u044C \u0435\u0449\u0451", size: Size.Large, buttonRef: buttonRef, intent: Intent.Secondary, wrapperClassName: styles.showMore, onClick: onLoadMore, loading: isLoading }) : null] });
};
export default DealsList;
