import { AgencyDealType } from './dealType';
import { AgencyOrder } from './order';
export const agenciesDefaultQueryParams = {
    regionId: null,
    order: AgencyOrder.TopDeals,
    dealType: AgencyDealType.Any,
    limit: 10,
    offset: 0,
    search: null
};
