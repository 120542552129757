import { baseApi } from '@shared/api/base';
import { ApiUrls } from '../config/apiUrls';
export const offersApi = baseApi.injectEndpoints({
    endpoints: builder => ({
        getAgentOffersSale: builder.query({
            query: ({ isMobileDevice, queryParams }) => ({
                url: ApiUrls.Offers,
                params: {
                    ...queryParams,
                    limit: isMobileDevice ? 2 : 3
                }
            })
        }),
        getAgentOffersRent: builder.query({
            query: ({ isMobileDevice, queryParams }) => ({
                url: ApiUrls.Offers,
                params: {
                    ...queryParams,
                    limit: isMobileDevice ? 2 : 3
                }
            })
        }),
        getAgencyOffersSale: builder.query({
            query: ({ isMobileDevice, queryParams }) => ({
                url: ApiUrls.Offers,
                params: {
                    ...queryParams,
                    limit: isMobileDevice ? 2 : 3
                }
            })
        }),
        getAgencyOffersRent: builder.query({
            query: ({ isMobileDevice, queryParams }) => ({
                url: ApiUrls.Offers,
                params: {
                    ...queryParams,
                    limit: isMobileDevice ? 2 : 3
                }
            })
        })
    })
});
export const { useLazyGetAgencyOffersRentQuery, useLazyGetAgencyOffersSaleQuery, useLazyGetAgentOffersRentQuery, useLazyGetAgentOffersSaleQuery } = offersApi;
