import { jsx as _jsx } from "react/jsx-runtime";
import { Avatar, AvatarIntent } from '@front-core/avatar';
import { clsx } from 'clsx';
import { useState, useEffect } from 'react';
import { testImagesUrl } from '@shared/lib/testImagesUrl';
import styles from './layoutAvatar.scss';
const LayoutAvatar = (props) => {
    const { name, imageSrc, imageSrc2x, imageWebpSrc, imageWebpSrc2x, onError, wrapperClassName } = props;
    const [image, setImage] = useState({
        imageSrc: undefined,
        imageSrc2x: undefined,
        imageWebpSrc: undefined,
        imageWebpSrc2x: undefined
    });
    const [isLoading, setIsLoading] = useState(true);
    const hasImages = Object.values(image).filter(path => typeof path !== 'undefined').length > 0;
    const loadImage = async () => {
        setIsLoading(true);
        const imagePaths = {
            imageSrc,
            imageSrc2x,
            imageWebpSrc,
            imageWebpSrc2x
        };
        try {
            await testImagesUrl(imagePaths);
            setImage(imagePaths);
        }
        catch (_a) {
            if (onError) {
                onError();
            }
        }
        finally {
            setIsLoading(false);
        }
    };
    useEffect(() => {
        loadImage().then();
    }, []);
    return _jsx("div", { className: clsx(styles.layoutAvatar, wrapperClassName, {
            [styles.hasError]: !hasImages && name
        }), "data-sentry-component": "LayoutAvatar", "data-sentry-source-file": "index.tsx", children: SSR ? _jsx(Avatar, { ...props, intent: hasImages ? AvatarIntent.Tertiary : AvatarIntent.Secondary, imageSrc: imageSrc, imageSrc2x: imageSrc2x, imageWebpSrc: imageWebpSrc, imageWebpSrc2x: imageWebpSrc2x }) : _jsx(Avatar, { ...props, intent: hasImages ? AvatarIntent.Tertiary : AvatarIntent.Secondary, imageSrc: isLoading ? imageSrc : image === null || image === void 0 ? void 0 : image.imageSrc, imageSrc2x: isLoading ? imageSrc2x : image === null || image === void 0 ? void 0 : image.imageSrc2x, imageWebpSrc: isLoading ? imageWebpSrc : image === null || image === void 0 ? void 0 : image.imageWebpSrc, imageWebpSrc2x: isLoading ? imageWebpSrc2x : image === null || image === void 0 ? void 0 : image.imageWebpSrc2x }) });
};
export default LayoutAvatar;
