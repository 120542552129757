import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BreakPoints, useBreakpoint } from '@front-core/utils';
import { Fragment } from 'react';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import OffersListSkeletonItem from '../item';
import styles from './offersListSkeletonContent.scss';
const OffersListSkeletonContent = () => {
    const media = useAppSelector(state => state.ui.media);
    const breakpoint = useBreakpoint(media === BreakPoints.MOBILE ? BreakPoints.MOBILE : BreakPoints.DESKTOP);
    const isMobile = breakpoint === BreakPoints.MOBILE;
    return _jsx("div", { className: styles.offersListSkeletonContent, "data-sentry-component": "OffersListSkeletonContent", "data-sentry-source-file": "index.tsx", children: isMobile ? _jsxs(Fragment, { children: [_jsx(OffersListSkeletonItem, {}), _jsx(OffersListSkeletonItem, {})] }) : _jsxs(Fragment, { children: [_jsx(OffersListSkeletonItem, {}), _jsx(OffersListSkeletonItem, {}), _jsx(OffersListSkeletonItem, {})] }) });
};
export default OffersListSkeletonContent;
