import { GrowthBook, configureCache } from '@growthbook/growthbook-react';
import * as Sentry from '@sentry/react';
import vars from '@shared/config/vars';
const { GROWTHBOOK_API_HOST, GROWTHBOOK_CLIENT_KEY, GROWTHBOOK_DATA } = vars();
const init = ({ apiHost = GROWTHBOOK_API_HOST, clientKey = GROWTHBOOK_CLIENT_KEY, enableDevMode = process.env.NODE_ENV === 'development', subscribeToChanges = true, attributes }) => {
    configureCache({
        cacheKey: 'pts_growthBook',
        backgroundSync: false,
        disableIdleStreams: false
    });
    const growthBook = new GrowthBook({
        apiHost,
        clientKey,
        enableDevMode,
        subscribeToChanges,
        attributes
    });
    if (!SSR) {
        try {
            if (GROWTHBOOK_DATA === null || GROWTHBOOK_DATA === void 0 ? void 0 : GROWTHBOOK_DATA.features) {
                growthBook.setFeatures(GROWTHBOOK_DATA === null || GROWTHBOOK_DATA === void 0 ? void 0 : GROWTHBOOK_DATA.features);
            }
            if (GROWTHBOOK_DATA === null || GROWTHBOOK_DATA === void 0 ? void 0 : GROWTHBOOK_DATA.attributes) {
                growthBook.setAttributes(GROWTHBOOK_DATA === null || GROWTHBOOK_DATA === void 0 ? void 0 : GROWTHBOOK_DATA.attributes).catch(() => null);
            }
        }
        catch (error) {
            Sentry.captureException(error);
        }
    }
    return growthBook;
};
export const GrowthBookHelper = {
    init
};
