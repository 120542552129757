import { createSlice, createSelector } from '@reduxjs/toolkit';
import { GEO } from '@shared/config/constants';
import { geoApi } from './api';
const initialState = {
    slug: null,
    city: {
        name: GEO.NAME,
        nameLocIn: GEO.NAME_LOC_IN
    },
    region: {
        guid: GEO.GUID
    },
    district: {
        list: []
    }
};
export const geoSlice = createSlice({
    name: 'geo',
    initialState,
    selectors: {
        selectFlatDistricts: createSelector((state) => state.district.list, (values) => values.flatMap(district => [
            {
                id: district.id,
                name: district.name
            }, ...district.children || []
        ]))
    },
    reducers: {
        setCityName(state, action) {
            state.city.name = action.payload;
        },
        setCityNameLocIn(state, action) {
            state.city.nameLocIn = action.payload;
        },
        setRegionGuid(state, action) {
            state.region.guid = action.payload;
        }
    },
    extraReducers: builder => {
        builder.addMatcher(geoApi.endpoints.getRegion.matchFulfilled, (state, { payload }) => {
            var _a, _b, _c, _d;
            if (payload.result) {
                state.city = {
                    name: (_a = payload.result) === null || _a === void 0 ? void 0 : _a.name,
                    nameLocIn: (_b = payload.result) === null || _b === void 0 ? void 0 : _b.nameLoc
                };
                state.region = {
                    guid: (_c = payload.result) === null || _c === void 0 ? void 0 : _c.uid
                };
                state.slug = (_d = payload.result) === null || _d === void 0 ? void 0 : _d.slug;
            }
        });
        builder.addMatcher(geoApi.endpoints.getDistricts.matchFulfilled, (state, { payload }) => {
            if (payload.result) {
                state.district.list = payload.result;
            }
        });
    }
});
export const { reducer, selectors: { selectFlatDistricts } } = geoSlice;
