import { createSlice, createSelector } from '@reduxjs/toolkit';
import { QueryStatus } from '@reduxjs/toolkit/query';
import { UrlHelper } from '@shared/lib/helpers/url';
import { mapQueryParams } from '@shared/lib/mapQueryParams';
import { AgentReviewOrder } from '../config/agentReviewsOrder';
import { AgentReviewsPeriod } from '../config/agentReviewsPeriod';
import { agentsDefaultQueryParams } from '../config/defaultQueryParams';
import { agentsApi } from './api';
const initialState = {
    agent: {
        details: {},
        reviews: {
            list: [],
            pagination: {},
            current: {
                status: null,
                surveyUID: null
            },
            queryParams: {
                agentCasId: '',
                offset: 0,
                order: AgentReviewOrder.Helpful,
                period: AgentReviewsPeriod.All,
                limit: 10
            },
            isPending: false,
            me: []
        },
        errors: null
    },
    dealsStats: [],
    isUpdateAgentPage: false,
    isPendingList: false,
    isRegionChangedEmptyList: false,
    list: [],
    pagination: {},
    queryParams: agentsDefaultQueryParams,
    regions: [],
    sort: {
        order: {},
        status: QueryStatus.pending
    }
};
export const agentsSlice = createSlice({
    name: 'agents',
    initialState,
    selectors: {
        selectPreviewReviewsList: createSelector((state) => state.agent.reviews.list, (values) => values.slice(0, 3)),
        selectIsSearchDistrict: createSelector((state) => state.queryParams.districtIds, (values) => Boolean(values === null || values === void 0 ? void 0 : values.length))
    },
    reducers: {
        setQueryParams(state, { payload }) {
            var _a;
            state.queryParams = {
                ...state.queryParams,
                ...payload
            };
            if (!SSR) {
                const params = new URLSearchParams(window.location.search);
                const mappedParams = mapQueryParams(payload, ((_a = state.pagination) === null || _a === void 0 ? void 0 : _a.limit) || 10);
                Object.keys(mappedParams).forEach(key => {
                    const value = mappedParams[key];
                    params.delete(key);
                    if (value !== null && value !== undefined && value !== '') {
                        if (Array.isArray(value)) {
                            value.forEach(item => params.append(key, String(item)));
                        }
                        else {
                            params.append(key, String(value));
                        }
                    }
                });
                history.replaceState({}, '', UrlHelper.getLocationPathnameWithParams(params));
            }
        },
        putQueryParams(state, { payload }) {
            if (!SSR) {
                history.replaceState({}, '', window.location.pathname);
            }
            state.queryParams = {
                regionId: initialState.queryParams.regionId,
                order: initialState.queryParams.order,
                offset: initialState.queryParams.offset,
                dealType: initialState.queryParams.dealType,
                search: initialState.queryParams.search,
                includeOtherRegions: initialState.queryParams.includeOtherRegions,
                districtIds: initialState.queryParams.districtIds,
                ...payload
            };
        },
        resetQueryParams(state) {
            if (!SSR) {
                history.replaceState({}, '', window.location.pathname);
            }
            state.queryParams = {
                ...state.queryParams,
                order: initialState.queryParams.order,
                dealType: initialState.queryParams.dealType,
                offset: initialState.queryParams.offset,
                search: initialState.queryParams.search,
                includeOtherRegions: initialState.queryParams.includeOtherRegions,
                districtIds: initialState.queryParams.districtIds
            };
        },
        setIsRegionChangedEmptyList(state, { payload }) {
            state.isRegionChangedEmptyList = payload;
        },
        setIsUpdateAgentPage(state, { payload }) {
            state.isUpdateAgentPage = payload;
        },
        resetAgentCreateReview(state) {
            state.agent.reviews.current = initialState.agent.reviews.current;
        },
        finishAgentCreateReview(state, action) {
            if (action === null || action === void 0 ? void 0 : action.payload) {
                state.agent.reviews.current = action.payload;
            }
            else {
                state.agent.reviews.current = initialState.agent.reviews.current;
            }
        },
        setAgentReviewsQueryParams(state, { payload }) {
            state.agent.reviews.queryParams = {
                ...state.agent.reviews.queryParams,
                ...payload
            };
        }
    },
    extraReducers: builder => {
        builder.addMatcher(agentsApi.endpoints.getAgents.matchFulfilled, (state, { payload }) => {
            state.list = payload.result;
            state.pagination = payload === null || payload === void 0 ? void 0 : payload.pagination;
            state.isPendingList = false;
        });
        builder.addMatcher(agentsApi.endpoints.getAgents.matchPending, state => {
            state.isPendingList = true;
        });
        builder.addMatcher(agentsApi.endpoints.getAgents.matchRejected, state => {
            state.isPendingList = false;
        });
        builder.addMatcher(agentsApi.endpoints.getAgent.matchFulfilled, (state, { payload }) => {
            state.agent.details = payload.result;
            state.agent.errors = null;
        });
        builder.addMatcher(agentsApi.endpoints.getAgent.matchRejected, (state, { payload }) => {
            const error = payload === null || payload === void 0 ? void 0 : payload.data.errors[0];
            if (error) {
                state.agent.errors = {
                    details: error.details
                };
            }
        });
        builder.addMatcher(agentsApi.endpoints.getAgentReviews.matchPending, state => {
            state.agent.reviews.isPending = true;
        });
        builder.addMatcher(agentsApi.endpoints.getAgentReviews.matchFulfilled, (state, { payload }) => {
            var _a;
            const reviews = state.agent.reviews.list.length && ((_a = payload.pagination) === null || _a === void 0 ? void 0 : _a.offset) ? state.agent.reviews.list : [];
            state.agent.reviews.list = [...reviews, ...payload.result];
            state.agent.reviews.pagination = payload.pagination;
            state.agent.reviews.isPending = false;
        });
        builder.addMatcher(agentsApi.endpoints.getAgentReviews.matchRejected, state => {
            state.agent.reviews.isPending = false;
        });
        builder.addMatcher(agentsApi.endpoints.getAgentReviewsMe.matchFulfilled, (state, { payload }) => {
            state.agent.reviews.me = payload.result;
        });
        builder.addMatcher(agentsApi.endpoints.getAgentRegions.matchFulfilled, (state, { payload }) => {
            state.regions = payload === null || payload === void 0 ? void 0 : payload.result;
        });
        builder.addMatcher(agentsApi.endpoints.getAgentsSort.matchFulfilled, (state, { payload }) => {
            if (payload.result) {
                state.sort.order = payload.result.order;
                state.queryParams.order = payload.result.order.options[0].value;
                state.sort.status = QueryStatus.fulfilled;
            }
        });
        builder.addMatcher(agentsApi.endpoints.getAgentsSort.matchPending, state => {
            state.sort.status = QueryStatus.pending;
        });
        builder.addMatcher(agentsApi.endpoints.getAgentsSort.matchRejected, state => {
            state.sort.status = QueryStatus.rejected;
        });
        builder.addMatcher(agentsApi.endpoints.getDealsStats.matchFulfilled, (state, { payload }) => {
            state.dealsStats = payload.result;
        });
    }
});
export const { reducer, actions: { setQueryParams, putQueryParams, resetQueryParams, setIsRegionChangedEmptyList, setIsUpdateAgentPage, resetAgentCreateReview, finishAgentCreateReview, setAgentReviewsQueryParams }, selectors: { selectPreviewReviewsList, selectIsSearchDistrict } } = agentsSlice;
