import { jsx as _jsx } from "react/jsx-runtime";
import { NavLink } from 'react-router-dom';
const CustomLink = (props) => {
    if (props.to) {
        return _jsx(NavLink, { ...props, to: props.to, state: {
                ...props.state,
                canGoBack: true
            } });
    }
    return _jsx("div", { className: props === null || props === void 0 ? void 0 : props.className, "data-sentry-component": "CustomLink", "data-sentry-source-file": "index.tsx", children: props === null || props === void 0 ? void 0 : props.children });
};
export default CustomLink;
