import { jsx as _jsx } from "react/jsx-runtime";
import { GrowthBookProvider } from '@growthbook/growthbook-react';
import { loadableReady } from '@loadable/component';
import * as Sentry from '@sentry/react';
import { StrictMode, useEffect } from 'react';
import { createRoot, hydrateRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import App from '@app/index';
import { configureAppStore } from '@store';
import vars from '@shared/config/vars';
import { GrowthBookHelper } from '@shared/lib/helpers/growthBook';
import sw from '@shared/lib/sw';
const { API_ENV, SENTRY_DSN, APP_NAMESPACE } = vars();
function render() {
    var _a;
    const store = configureAppStore(window.__SSR_STATE__);
    const growthBook = GrowthBookHelper.init({});
    const isWebView = (_a = store.getState().ui) === null || _a === void 0 ? void 0 : _a.isWebView;
    if (!isWebView) {
        sw();
    }
    const clientApp = _jsx(StrictMode, { children: _jsx(GrowthBookProvider, { growthbook: growthBook, children: _jsx(Provider, { store: store, serverState: window.__SSR_STATE__, children: _jsx(HelmetProvider, { children: _jsx(BrowserRouter, { children: _jsx(App, {}) }) }) }) }) });
    Sentry.init({
        dsn: SENTRY_DSN,
        environment: API_ENV,
        release: `${APP_NAMESPACE}@${PROJECT_VERSION}`,
        integrations: [Sentry.reactRouterV6BrowserTracingIntegration({
                useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes
            })],
        tracesSampleRate: 1.0
    });
    loadableReady(() => {
        var _a;
        const container = document.querySelector('#app');
        if (!container) {
            throw Error('Target container is not a DOM element.');
        }
        if ((_a = store.getState().ui) === null || _a === void 0 ? void 0 : _a.mismatch) {
            const root = createRoot(container);
            root.render(clientApp);
        }
        else {
            hydrateRoot(container, clientApp);
        }
    }).catch((error) => {
        throw Error(`An error occurred during execution loadable step: ${String(error)}`);
    });
}
render();
