const set = (name, value, options = {}) => {
    if (SSR) {
        throw Error('document is missing from SSR');
    }
    const currentOptions = {
        path: '/',
        ...options
    };
    if (currentOptions.expires instanceof Date) {
        currentOptions.expires = currentOptions.expires.toUTCString();
    }
    let updatedCookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;
    for (const optionKey in currentOptions) {
        if (Object.prototype.hasOwnProperty.call(currentOptions, optionKey)) {
            updatedCookie += `; ${optionKey}`;
            const optionValue = currentOptions[optionKey];
            if (optionValue !== true) {
                updatedCookie += `=${optionValue}`;
            }
        }
    }
    document.cookie = updatedCookie;
};
const get = (name) => {
    if (SSR) {
        throw Error('document is missing from SSR');
    }
    const matches = new RegExp(`(?:^|; )${name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1')}=([^;]*)`).exec(document.cookie);
    return matches ? decodeURIComponent(matches[1]) : null;
};
const objectToString = (cookies) => Object.entries(cookies)
    .filter(([, value]) => value !== null)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('; ')
    .replace(/%(?:7B|7D|3A|7C|24|2B|2F|3D)/g, decodeURIComponent);
export const CookieHelper = {
    get,
    set,
    objectToString
};
