import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Target } from '@front-core/link';
import { LabelSmallRegular } from '@front-core/typography';
import { useBreakpoint, BreakPoints } from '@front-core/utils';
import { clsx } from 'clsx';
import { generatePath } from 'react-router-dom';
import { PATH } from '@shared/config/constants';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import CustomLink from '@shared/ui/customLink';
import { getShortName } from '../../../../../lib/getShortName';
import styles from './reviewSubTitle.scss';
const ReviewSubtitle = ({ isAgentScope, createdAt, dealIssuedAt, agent, className }) => {
    const media = useAppSelector(state => state.ui.media);
    const isWebView = useAppSelector(state => state.ui.isWebView);
    const breakpoint = useBreakpoint(media === BreakPoints.MOBILE ? BreakPoints.MOBILE : BreakPoints.DESKTOP);
    const isMobile = breakpoint === BreakPoints.MOBILE;
    const agentUrl = generatePath(PATH.AGENT, {
        agentCasId: String(agent === null || agent === void 0 ? void 0 : agent.casId)
    });
    const shortName = (agent === null || agent === void 0 ? void 0 : agent.fullname) ? getShortName(agent.fullname.split(' ')) : null;
    if (dealIssuedAt) {
        if (isAgentScope) {
            return _jsxs(LabelSmallRegular, { tag: "span", wrapper: false, className: clsx(className, styles.reviewSubTitle), children: [createdAt, ", \u0441\u0434\u0435\u043B\u043A\u0430 \u0431\u044B\u043B\u0430 ", dealIssuedAt] });
        }
        return _jsxs(LabelSmallRegular, { wrapper: false, className: clsx(className, styles.reviewSubTitle), children: [isMobile ? _jsx("div", { children: createdAt }) : `${createdAt}, сделку провёл риелтор `, isMobile ? 'Сделку провёл риелтор ' : null, _jsx(CustomLink, { to: agentUrl, className: styles.link, target: isWebView ? undefined : Target.Blank, children: shortName }), ' ', dealIssuedAt] });
    }
    return _jsx(LabelSmallRegular, { tag: "span", wrapper: false, className: clsx(className, styles.reviewSubTitle), "data-sentry-element": "LabelSmallRegular", "data-sentry-component": "ReviewSubtitle", "data-sentry-source-file": "index.tsx", children: createdAt });
};
export default ReviewSubtitle;
