import { jsx as _jsx } from "react/jsx-runtime";
import * as Sentry from '@sentry/react';
import { Component } from 'react';
import ErrorFallback from '@shared/ui/errorFallback';
export default class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false
        };
    }
    static getDerivedStateFromError() {
        return {
            hasError: true
        };
    }
    componentDidCatch(error) {
        Sentry.captureException(error);
        this.setState({
            hasError: true
        });
    }
    render() {
        const { hasError } = this.state;
        const { children } = this.props;
        if (hasError) {
            return _jsx(ErrorFallback, { isShow: hasError, "data-sentry-element": "ErrorFallback", "data-sentry-component": "ErrorBoundary", "data-sentry-source-file": "index.tsx" });
        }
        return children;
    }
}
