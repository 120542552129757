import { matchPath } from 'react-router-dom';
import { agenciesApi, agenciesSlice } from '@entities/agencies';
import { agentsApi, agentsSlice } from '@entities/agents';
import { PATH } from '@shared/config/constants';
const updateAgentReview = async (listenerApi) => {
    var _a, _b, _c;
    const store = listenerApi.getState();
    const { agents } = store;
    const agentCasId = (_a = agents.agent.details) === null || _a === void 0 ? void 0 : _a.casId;
    const searchParams = agents === null || agents === void 0 ? void 0 : agents.queryParams;
    const regionId = (_c = (_b = store.geo) === null || _b === void 0 ? void 0 : _b.region) === null || _c === void 0 ? void 0 : _c.guid;
    const params = {
        ...searchParams,
        regionId
    };
    if (agentCasId && searchParams) {
        listenerApi.dispatch(agentsSlice.setIsUpdateAgentPage(true));
        const responseAgent = await listenerApi.dispatch(agentsApi.endpoints.getAgent
            .initiate({
            agentCasId: String(agentCasId),
            companyRegionId: regionId
        }, { forceRefetch: true }));
        if (responseAgent === null || responseAgent === void 0 ? void 0 : responseAgent.data) {
            const { casId } = responseAgent.data.result;
            await listenerApi.dispatch(agentsApi.endpoints.getAgentReviews.initiate({
                agentCasId: casId,
                offset: 0
            }, { forceRefetch: true }));
            await listenerApi.dispatch(agentsApi.endpoints.getAgentReviewsMe.initiate({
                agentCasId: casId
            }, { forceRefetch: true }));
        }
        await listenerApi.dispatch(agentsApi.endpoints.getAgents.initiate(params, { forceRefetch: true }));
        listenerApi.dispatch(agentsSlice.setIsUpdateAgentPage(false));
    }
};
const updateAgencyReview = async (listenerApi) => {
    var _a;
    const store = listenerApi.getState();
    const { agencies } = store;
    const agencyId = ((_a = agencies.agency.details) === null || _a === void 0 ? void 0 : _a.id) ? String(agencies.agency.details.id) : null;
    if (agencyId) {
        listenerApi.dispatch(agenciesSlice.setIsAgencyUpdatePage(true));
        const responseAgency = await listenerApi.dispatch(agenciesApi.endpoints.getAgency.initiate({
            agencyId
        }));
        if (responseAgency === null || responseAgency === void 0 ? void 0 : responseAgency.data) {
            const { id } = responseAgency.data.result;
            await listenerApi.dispatch(agenciesApi.endpoints.getAgencyReviews.initiate({ companyId: String(id) }, { forceRefetch: true }));
        }
        listenerApi.dispatch(agenciesSlice.setIsAgencyUpdatePage(false));
    }
};
export const agentsReviewListener = (startListening) => {
    startListening({
        actionCreator: agentsSlice.finishAgentCreateReview,
        effect: async (_action, listenerApi) => {
            listenerApi.cancelActiveListeners();
            const pathname = typeof window !== 'undefined' ? window.location.pathname : null;
            const agentPath = pathname && matchPath(PATH.AGENT, pathname);
            const agencyPath = pathname && matchPath(PATH.AGENCY, pathname);
            if (!SSR) {
                if (agentPath) {
                    await updateAgentReview(listenerApi);
                }
                if (agencyPath) {
                    await updateAgencyReview(listenerApi);
                }
            }
        }
    });
};
