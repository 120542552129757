import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    path: {
        pattern: '',
        name: ''
    },
    queryParams: null,
    canonical: '',
    params: null
};
const urlSlice = createSlice({
    name: 'url',
    initialState,
    reducers: {
        setRouteData: (state, action) => {
            state.path = action.payload.path;
            state.params = {
                ...state.params,
                ...action.payload.params
            };
        },
        setQueryParams: (state, action) => {
            if (action.payload) {
                state.queryParams = action.payload;
            }
        },
        setCanonical: (state, action) => {
            state.canonical = action.payload;
        }
    }
});
export const { reducer, actions: { setRouteData, setCanonical, setQueryParams } } = urlSlice;
