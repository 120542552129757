import AgenciesPage from '@pages/agencies';
import AgencyPage from '@pages/agency';
import AgentPage from '@pages/agent';
import AgentsPage from '@pages/agents';
import FAQAgentPage from '@pages/faq/agent';
import FAQAppPage from '@pages/faq/app';
import FAQChoiceAgentPage from '@pages/faq/choiceAgent';
import NotFoundPage from '@pages/notFound';
import { PATH, ROUTES } from '@shared/config/constants';
const routesConfig = [
    {
        path: PATH.AGENTS,
        element: AgentsPage,
        key: ROUTES.AGENTS
    },
    {
        path: PATH.AGENT,
        element: AgentPage,
        key: ROUTES.AGENT
    },
    {
        path: PATH.AGENCIES,
        element: AgenciesPage,
        key: ROUTES.AGENCIES
    },
    {
        path: PATH.AGENCY,
        element: AgencyPage,
        key: ROUTES.AGENCY
    },
    {
        path: PATH.FAQ_APP,
        element: FAQAppPage,
        key: ROUTES.FAQ_APP
    },
    {
        path: PATH.FAQ_CHOICE_AGENT,
        element: FAQChoiceAgentPage,
        key: ROUTES.FAQ_CHOICE_AGENT
    },
    {
        path: PATH.FAQ_AGENT,
        element: FAQAgentPage,
        key: ROUTES.FAQ_AGENT
    },
    {
        path: PATH.NOT_FOUND,
        element: NotFoundPage,
        key: ROUTES.NOT_FOUND
    }
];
export default routesConfig;
