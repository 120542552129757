import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import qs from 'qs';
import { sanitizeXSS } from '@server/lib/sanitizeXSS';
import { CookieHelper } from '@shared/lib/helpers/cookie';
import { ObjectHelper } from '@shared/lib/helpers/object';
import vars from '../config/vars';
import { apiUrls } from './urls';
const { API_ENV } = vars();
export const getBaseQuery = (apiName) => {
    const baseQuery = fetchBaseQuery({
        baseUrl: apiUrls[apiName][API_ENV],
        credentials: 'include',
        prepareHeaders: (headers, api) => {
            const state = api.getState();
            if ((state === null || state === void 0 ? void 0 : state.ui.serverCookies) && SSR) {
                const cookie = CookieHelper.objectToString(state.ui.serverCookies);
                headers.set('Cookie', sanitizeXSS(cookie));
            }
            return headers;
        },
        paramsSerializer: params => qs.stringify(ObjectHelper.convertCamelCaseToSnakeCase(params), {
            skipNulls: true,
            indices: false
        })
    });
    return async (args, api, extraOptions) => {
        if (typeof args === 'object' && 'body' in args && args.body) {
            args.body = ObjectHelper.convertCamelCaseToSnakeCase(args.body);
        }
        const result = await baseQuery(args, api, extraOptions);
        if (result.data) {
            result.data = ObjectHelper.convertSnakeCaseToCamelCase(result.data);
        }
        if (result.error) {
            result.error = ObjectHelper.convertSnakeCaseToCamelCase({
                ...result.error,
                data: {
                    success: result.error.data && typeof result.error.data === 'object' && 'success' in result.error.data && result.error.data.success,
                    errors: result.error.data && typeof result.error.data === 'object' && 'errors' in result.error.data ? result.error.data.errors : {}
                }
            });
        }
        return result;
    };
};
