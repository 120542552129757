import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AvatarIntent, AvatarType } from '@front-core/avatar';
import { LabelSmallRegular, LabelStandardMedium } from '@front-core/typography';
import { clsx } from 'clsx';
import { useMemo } from 'react';
import { generatePath } from 'react-router-dom';
import { PATH } from '@shared/config/constants';
import { getDateDifferenceString } from '@shared/lib/getDateDifferenceString';
import { StringHelper } from '@shared/lib/helpers/string';
import CustomLink from '@shared/ui/customLink';
import LayoutAvatar from '@shared/ui/layout/avatar';
import LayoutRating from '@shared/ui/layout/rating';
import { RatingType } from '@shared/ui/layout/rating/config';
import styles from './agenciesCardDesktop.scss';
const AgenciesCardDesktop = ({ agency, featureElement, onClickLink }) => {
    const { rating, stats, logoUrl, name, createdAt, slug } = agency;
    const offersCount = stats === null || stats === void 0 ? void 0 : stats.offersCount;
    const dealsCount = stats === null || stats === void 0 ? void 0 : stats.dealsCount;
    const ratingVotes = rating === null || rating === void 0 ? void 0 : rating.votes;
    const hasRating = ratingVotes && (rating === null || rating === void 0 ? void 0 : rating.avg);
    const hasAvg = Boolean(rating === null || rating === void 0 ? void 0 : rating.avg);
    const url = useMemo(() => generatePath(PATH.AGENCY, {
        companyId: slug
    }), [agency]);
    const getRatingVotes = () => {
        const votes = `${StringHelper.formatCurrency(ratingVotes || 0)} ${StringHelper.getPluralForm(ratingVotes || 0, ['отзыв', 'отзыва', 'отзывов'])}`;
        if (ratingVotes && ratingVotes > 0) {
            return votes;
        }
        return 'Нет оценок и отзывов';
    };
    const getOffers = () => {
        if (offersCount > 0) {
            return StringHelper.formatCurrency(offersCount || 0);
        }
        return '—';
    };
    const getDeals = () => {
        if (dealsCount > 0) {
            return StringHelper.formatCurrency(dealsCount || 0);
        }
        return '—';
    };
    const getLastReview = () => {
        var _a, _b, _c, _d;
        let result = '';
        if ((_b = (_a = rating.lastReview) === null || _a === void 0 ? void 0 : _a.username) === null || _b === void 0 ? void 0 : _b.length) {
            result += `${rating.lastReview.username}: `;
        }
        if ((_d = (_c = rating.lastReview) === null || _c === void 0 ? void 0 : _c.message) === null || _d === void 0 ? void 0 : _d.length) {
            result += rating.lastReview.message;
        }
        return result;
    };
    return _jsxs("div", { className: styles.container, "data-sentry-component": "AgenciesCardDesktop", "data-sentry-source-file": "index.tsx", children: [_jsx(CustomLink, { to: url, className: styles.link, onClick: onClickLink, rel: "noopener", "data-sentry-element": "CustomLink", "data-sentry-source-file": "index.tsx" }), _jsxs("div", { className: styles.inner, children: [_jsxs("div", { className: styles.personalData, children: [_jsx("div", { className: styles.avatar, children: _jsx(LayoutAvatar, { name: name, intent: logoUrl ? AvatarIntent.Tertiary : AvatarIntent.Secondary, type: AvatarType.Rect, imageSrc: logoUrl || undefined, "data-sentry-element": "LayoutAvatar", "data-sentry-source-file": "index.tsx" }) }), _jsxs("div", { className: styles.info, children: [_jsx(LabelStandardMedium, { tag: "strong", wrapper: false, className: styles.title, "data-sentry-element": "LabelStandardMedium", "data-sentry-source-file": "index.tsx", children: name }), _jsxs(LabelSmallRegular, { tag: "span", wrapper: false, className: styles.secondaryText, "data-sentry-element": "LabelSmallRegular", "data-sentry-source-file": "index.tsx", children: [getDateDifferenceString(createdAt), " \u043D\u0430 \u0414\u043E\u043C\u043A\u043B\u0438\u043A"] })] })] }), _jsxs("div", { className: clsx(styles.aboutCompany, {
                            [styles.hasReview]: !hasRating
                        }), children: [_jsxs("div", { className: styles.review, children: [hasAvg ? _jsx(LayoutRating, { hasGrade: true, rating: rating.avg, containerClassName: styles.rating, type: RatingType.Secondary }) : null, _jsx(LabelSmallRegular, { tag: "span", wrapper: false, className: styles.secondaryText, "data-sentry-element": "LabelSmallRegular", "data-sentry-source-file": "index.tsx", children: getRatingVotes() })] }), rating.lastReview ? _jsx("p", { className: styles.lastReview, children: getLastReview() }) : null] }), _jsx(LabelStandardMedium, { tag: "strong", wrapper: false, className: clsx(styles.strongText, {
                            [styles.secondaryText]: !dealsCount
                        }), "data-sentry-element": "LabelStandardMedium", "data-sentry-source-file": "index.tsx", children: getDeals() }), _jsx(LabelStandardMedium, { tag: "strong", wrapper: false, className: clsx(styles.strongText, {
                            [styles.secondaryText]: !offersCount
                        }), "data-sentry-element": "LabelStandardMedium", "data-sentry-source-file": "index.tsx", children: getOffers() }), featureElement ? _jsx("div", { className: styles.button, children: featureElement }) : null] })] });
};
export default AgenciesCardDesktop;
