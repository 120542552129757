import qs from 'qs';
import { UTMParams } from '@shared/config/utmParams';
const getUrlWithParams = (url, urlParams) => {
    const params = { ...urlParams };
    for (const key in params) {
        if (!params[key]) {
            delete params[key];
        }
    }
    if (Object.values(params).length) {
        return `${url}?${qs.stringify(params)}`;
    }
    return url;
};
const getUrlParams = () => {
    if (typeof window !== 'undefined') {
        return qs.parse(window.location.search.replace('?', ''), { arrayLimit: 1000 });
    }
    return {};
};
const getLocationPathnameWithParams = (params) => {
    if (typeof window !== 'undefined') {
        if (params.toString().length > 0) {
            return `${window.location.pathname}?${params.toString()}`;
        }
        return window.location.pathname;
    }
    return '';
};
const getUTMParams = () => {
    const params = getUrlParams();
    const result = {};
    for (const paramsKey in params) {
        if (params[paramsKey] && UTMParams.includes(paramsKey)) {
            result[paramsKey] = params[paramsKey];
        }
    }
    return result;
};
const removeTrailingSlash = (url) => url.endsWith('/') ? url.slice(0, url.length - 1) : url;
export const UrlHelper = {
    getUTMParams,
    getUrlWithParams,
    getUrlParams,
    getLocationPathnameWithParams,
    removeTrailingSlash
};
