import { jsx as _jsx } from "react/jsx-runtime";
import { Paper } from '@front-core/box';
import { Button, Intent } from '@front-core/button';
import { EmptyScreen, ErrorCode } from '@front-core/empty-screen';
import RefreshIcon from '@front-core/icon/dist/Refresh';
import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import styles from './errorFallback.scss';
const ErrorFallback = ({ isShow }) => {
    const handleClickRefreshPage = () => {
        if (typeof window !== 'undefined') {
            window.location.reload();
        }
    };
    const handleClickGoBack = () => {
        if (typeof window !== 'undefined') {
            history.back();
        }
    };
    useEffect(() => {
        if (isShow) {
            Sentry.captureException(`ErrorFallback: ${window.location.href}`);
        }
    }, []);
    if (!isShow) {
        return null;
    }
    return _jsx(Paper, { className: styles.container, "data-sentry-element": "Paper", "data-sentry-component": "ErrorFallback", "data-sentry-source-file": "index.tsx", children: _jsx(EmptyScreen, { title: "400 \u041E\u0448\u0438\u0431\u043A\u0430 \u043D\u0430 \u0441\u0442\u0440\u0430\u043D\u0438\u0446\u0435", text: "\u041F\u043E\u043F\u0440\u043E\u0431\u0443\u0439\u0442\u0435 \u043E\u0431\u043D\u043E\u0432\u0438\u0442\u044C \u0441\u0442\u0440\u0430\u043D\u0438\u0446\u0443 \u043F\u043E\u0437\u0436\u0435", image: ErrorCode.BadRequest, buttons: [_jsx(Button, { text: "\u041E\u0431\u043D\u043E\u0432\u0438\u0442\u044C", icon: _jsx(RefreshIcon, {}), onClick: handleClickRefreshPage }, "refresh"), _jsx(Button, { text: "\u041D\u0430\u0437\u0430\u0434", intent: Intent.Secondary, onClick: handleClickGoBack }, "goBack")], "data-sentry-element": "EmptyScreen", "data-sentry-source-file": "index.tsx" }) });
};
export default ErrorFallback;
