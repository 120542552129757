export var AgentTypes;
(function (AgentTypes) {
    AgentTypes["Agent"] = "agent";
    AgentTypes["PrivateAgent"] = "private_agent";
    AgentTypes["Freelancer"] = "freelancer";
    AgentTypes["Individual"] = "individual";
})(AgentTypes || (AgentTypes = {}));
export const AgentTypesLabel = {
    [AgentTypes.Agent]: '',
    [AgentTypes.PrivateAgent]: 'Частный риелтор',
    [AgentTypes.Freelancer]: 'Самозанятый',
    [AgentTypes.Individual]: 'Частный риелтор'
};
