import { isAnyOf } from '@reduxjs/toolkit';
import { matchPath } from 'react-router-dom';
import { agentsSlice, agentsApi } from '@entities/agents';
import { urlSlice } from '@entities/url';
import { PATH } from '@shared/config/constants';
import { UrlHelper } from '@shared/lib/helpers/url';
export const agentsQueryParamsListeners = (startListening) => {
    const matcher = isAnyOf(agentsSlice.setQueryParams, agentsSlice.resetQueryParams, agentsSlice.putQueryParams);
    startListening({
        matcher,
        effect: async (action, listenerApi) => {
            var _a, _b, _c, _d;
            listenerApi.cancelActiveListeners();
            const originalState = listenerApi.getOriginalState();
            const state = listenerApi.getState();
            const prevQueryParams = (_a = originalState.agents) === null || _a === void 0 ? void 0 : _a.queryParams;
            const queryParams = (_b = state.agents) === null || _b === void 0 ? void 0 : _b.queryParams;
            const prevRegionId = (_c = originalState.geo) === null || _c === void 0 ? void 0 : _c.region.guid;
            const regionId = (_d = state.geo) === null || _d === void 0 ? void 0 : _d.region.guid;
            const pathname = typeof window !== 'undefined' ? window.location.pathname : null;
            const matchedPath = pathname && (matchPath(PATH.AGENTS, pathname) || matchPath(PATH.AGENT, pathname));
            const currentRegionId = queryParams.regionId || regionId;
            const hasDiffQueryParams = JSON.stringify(prevQueryParams) !== JSON.stringify(queryParams);
            const hasDiffRegionId = prevRegionId !== regionId;
            if (!SSR && ((matchedPath && queryParams) || hasDiffQueryParams || hasDiffRegionId)) {
                const params = (action === null || action === void 0 ? void 0 : action.payload) ? {
                    ...queryParams,
                    ...action.payload,
                    regionId: currentRegionId
                } : {
                    ...queryParams,
                    regionId: currentRegionId
                };
                await listenerApi.dispatch(agentsApi.endpoints.getAgents.initiate(params, { forceRefetch: true }));
                listenerApi.dispatch(urlSlice.setQueryParams(UrlHelper.getUrlParams()));
            }
        }
    });
};
