const calculatePercentage = (fraction, number) => {
    if (Number(fraction) > 0) {
        if (typeof fraction === 'number') {
            return (fraction / number) * 100;
        }
        return (Number(fraction) / number) * 100;
    }
    return 0;
};
const getOffset = (page, limit) => page === 1 ? 0 : (page - 1) * (limit || 10);
const getNumberLocaleFormat = (number) => {
    const parts = Number(number).toLocaleString('ru-RU', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    })
        .split(',');
    if (parts[1] === '00') {
        return parts[0];
    }
    return parts.join(' ');
};
export const NumberHelper = {
    calculatePercentage,
    getNumberLocaleFormat,
    getOffset
};
