export var AgentsSortScope;
(function (AgentsSortScope) {
    AgentsSortScope["List"] = "list";
    AgentsSortScope["Agency"] = "agency";
})(AgentsSortScope || (AgentsSortScope = {}));
export var RegionScope;
(function (RegionScope) {
    RegionScope["Agents"] = "agents";
    RegionScope["Agencies"] = "agencies";
})(RegionScope || (RegionScope = {}));
