import { createSlice } from '@reduxjs/toolkit';
import { dealsApi } from './api';
const initialState = {
    agent: [],
    agency: []
};
export const dealsSlice = createSlice({
    name: 'deals',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addMatcher(dealsApi.endpoints.getAgentDeals.matchFulfilled, (state, { payload }) => {
            if (payload.result) {
                state.agent = payload.result;
            }
        });
        builder.addMatcher(dealsApi.endpoints.getAgencyDeals.matchFulfilled, (state, { payload }) => {
            if (payload.result) {
                state.agency = payload.result;
            }
        });
    }
});
export const { reducer } = dealsSlice;
