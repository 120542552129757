import { AgentDealType } from './dealType';
import { AgentOrder } from './order';
export const agentsDefaultQueryParams = {
    regionId: null,
    order: AgentOrder.Default,
    dealType: AgentDealType.Any,
    offset: 0,
    search: null,
    includeOtherRegions: false,
    districtIds: null
};
