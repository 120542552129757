export const testImagesUrl = (paths) => new Promise((resolve, reject) => {
    Object.keys(paths).forEach(path => {
        if (paths[path]) {
            const image = new Image();
            image.addEventListener('load', resolve);
            image.addEventListener('error', reject);
            image.src = paths[path];
        }
    });
});
