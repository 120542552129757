import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';
import { agenciesQueryParamsListeners } from '@app/listeners/agenciesQueryParams';
import { agentsQueryParamsListeners } from '@app/listeners/agentsQueryParams';
import { agentsReviewListener } from '@app/listeners/agentsReview';
import { uiListener } from '@app/listeners/ui';
import { rootReducer } from '@app/reducers/root';
import { baseApi } from '@shared/api/base';
import { ppsApi } from '@shared/api/pps';
import { researchApi } from '@shared/api/research';
import { listenerMiddleware, startAppListening } from '@shared/lib/listenerMiddleware';
const middlewares = [
    listenerMiddleware.middleware,
    ppsApi.middleware,
    baseApi.middleware,
    researchApi.middleware
];
const removeInlineState = () => {
    if (!SSR) {
        const scriptCollection = document.querySelectorAll('script');
        scriptCollection.forEach(script => {
            var _a, _b;
            if ((_b = (_a = script.firstChild) === null || _a === void 0 ? void 0 : _a.textContent) === null || _b === void 0 ? void 0 : _b.includes('window.__SSR_STATE__')) {
                script.remove();
            }
        });
    }
};
export const configureAppStore = (preloadedState) => {
    const store = configureStore({
        reducer: rootReducer,
        middleware: getDefaultMiddleware => getDefaultMiddleware().concat(middlewares),
        devTools: process.env.NODE_ENV !== 'production',
        preloadedState
    });
    setupListeners(store.dispatch);
    if (preloadedState) {
        delete window.__SSR_STATE__;
        agentsQueryParamsListeners(startAppListening);
        agenciesQueryParamsListeners(startAppListening);
        agentsReviewListener(startAppListening);
        uiListener(startAppListening);
        removeInlineState();
    }
    return store;
};
const store = configureAppStore();
export { store };
