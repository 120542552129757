import { DateTimeHelper } from '@shared/lib/helpers/dateTime';
export const transformResponseAgentReview = (response) => {
    if (response.success) {
        return {
            ...response,
            result: response.result.map(review => ({
                ...review,
                answer: review.answer ? {
                    ...review.answer,
                    createdAt: DateTimeHelper.format(review.answer.createdAt, 'D MMMM YYYY')
                } : null,
                createdAt: DateTimeHelper.format(review.createdAt, 'D MMMM YYYY'),
                dealIssuedAt: review.dealIssuedAt ? DateTimeHelper.format(review.dealIssuedAt, 'D MMMM YYYY') : null
            }))
        };
    }
    return response;
};
