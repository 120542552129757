import { createApi } from '../createApi';
import { getBaseQuery } from '../getBaseQuery';
import { ApiNames } from '../names';
import { ppsTagTypes } from './tags';
export const ppsApi = createApi({
    tagTypes: ppsTagTypes,
    reducerPath: 'apiPPS',
    baseQuery: getBaseQuery(ApiNames.PPS),
    endpoints: () => ({})
});
