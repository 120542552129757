import { jsx as _jsx } from "react/jsx-runtime";
import { BreakPoints, useBreakpoint } from '@front-core/utils';
import { AgenciesMetricHashes } from '@shared/config/metrics';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import { sendMetrics } from '@shared/lib/sendMetrics';
import AgenciesCardDesktop from './components/desktop';
import AgenciesCardMobile from './components/mobile';
const AgenciesCard = ({ agency, featureElement }) => {
    const media = useAppSelector(state => { var _a; return (_a = state.ui) === null || _a === void 0 ? void 0 : _a.media; });
    const breakpoint = useBreakpoint(media === BreakPoints.MOBILE ? BreakPoints.MOBILE : BreakPoints.DESKTOP);
    const isMobile = breakpoint === BreakPoints.MOBILE;
    const handleClickSendMetrics = () => {
        sendMetrics({
            eventType: 'click',
            elementId: AgenciesMetricHashes.ClickLinkAgencyCard,
            elementType: 'link'
        });
    };
    return isMobile ? _jsx(AgenciesCardMobile, { agency: agency, onClickLink: handleClickSendMetrics, featureElement: featureElement, "data-sentry-element": "AgenciesCardMobile", "data-sentry-component": "AgenciesCard", "data-sentry-source-file": "index.tsx" }) : _jsx(AgenciesCardDesktop, { agency: agency, onClickLink: handleClickSendMetrics, featureElement: featureElement, "data-sentry-element": "AgenciesCardDesktop", "data-sentry-component": "AgenciesCard", "data-sentry-source-file": "index.tsx" });
};
export default AgenciesCard;
