import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useBreakpoint, BreakPoints } from '@front-core/utils';
import { useState, useMemo, useEffect, memo } from 'react';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import styles from './clampedText.scss';
const ClampedText = ({ text, buttonText = 'Читать далее', maxLength = {
    mobile: 200,
    desktop: 400
}, className }) => {
    const media = useAppSelector(state => state.ui.media);
    const [isExpanded, setIsExpanded] = useState(false);
    const breakpoint = useBreakpoint(media === BreakPoints.MOBILE ? BreakPoints.MOBILE : BreakPoints.DESKTOP);
    const isMobile = breakpoint === BreakPoints.MOBILE;
    const hasClampedText = isMobile ? text.length > maxLength.mobile : text.length > maxLength.desktop;
    const hasButton = !isExpanded && hasClampedText;
    const getText = (length) => {
        if (length >= text.length) {
            return text;
        }
        const trimmedText = text.slice(0, length);
        const lastSpaceIndex = trimmedText.lastIndexOf(' ');
        const finalText = lastSpaceIndex !== -1 ? trimmedText.slice(0, lastSpaceIndex) : trimmedText;
        return `${finalText}...`;
    };
    const handleClick = () => {
        setIsExpanded(prevState => !prevState);
    };
    const clampedText = useMemo(() => getText(isMobile ? maxLength.mobile : maxLength.desktop), [breakpoint]);
    useEffect(() => {
        if (isExpanded && hasClampedText) {
            setIsExpanded(false);
        }
    }, [breakpoint]);
    return _jsxs("div", { className: className, "data-sentry-component": "ClampedText", "data-sentry-source-file": "index.tsx", children: [_jsx("p", { className: styles.description, children: hasButton ? clampedText : text }), hasButton ? _jsx("button", { onClick: handleClick, className: styles.button, children: buttonText }) : null] });
};
export default memo(ClampedText);
