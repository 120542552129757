import { jsx as _jsx } from "react/jsx-runtime";
import { Loader, LoaderSize } from '@front-core/loader';
import { useState, useEffect } from 'react';
const styles = {
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#FFFFFF',
    transition: 'opacity 0.2s ease'
};
const Preloader = ({ timeInMS = 300 }) => {
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
        }, timeInMS);
    }, []);
    return _jsx("div", { style: {
            position: 'fixed',
            ...styles,
            opacity: isLoading ? 1 : 0,
            zIndex: isLoading ? 2000 : -1
        }, "data-sentry-component": "Preloader", "data-sentry-source-file": "index.tsx", children: _jsx(Loader, { size: LoaderSize.Large, "data-sentry-element": "Loader", "data-sentry-source-file": "index.tsx" }) });
};
export default Preloader;
