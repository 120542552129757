import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import StarIcon from '@front-core/icon/dist/Star';
import { clsx } from 'clsx';
import { memo } from 'react';
import { NumberHelper } from '@shared/lib/helpers/number';
import { RatingType, RatingFontSize } from './config';
import styles from './layoutRating.scss';
const MAX_STARS = 5;
const stars = Array.from({
    length: MAX_STARS
}, (_value, index) => index + 1);
const LayoutRating = ({ rating, hasGrade, type = RatingType.Primary, fontSize = RatingFontSize.Small, starClassName, containerClassName }) => _jsxs("div", { className: clsx(styles.container, styles[type], containerClassName), "data-sentry-component": "LayoutRating", "data-sentry-source-file": "index.tsx", children: [type === RatingType.Secondary ? _jsx(StarIcon, { className: clsx(styles.star, styles.activeStar, starClassName) }) : null, hasGrade ? _jsx("span", { className: clsx(styles.grade, styles[fontSize]), children: rating }) : null, type === RatingType.Primary ? _jsxs("div", { className: styles.starsContainer, children: [_jsx("div", { className: styles.defaultStars, children: stars.map(star => _jsx(StarIcon, { className: clsx(styles.star, starClassName) }, star)) }), _jsx("div", { className: styles.activeStars, style: {
                        width: `${NumberHelper.calculatePercentage(rating, MAX_STARS)}%`
                    }, children: stars.map(star => _jsx(StarIcon, { className: clsx(styles.star, styles.activeStar, starClassName) }, star)) })] }) : null] });
const propsAreEqual = (prevProps, nextProps) => prevProps.rating === nextProps.rating;
export default memo(LayoutRating, propsAreEqual);
