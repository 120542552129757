import ENV from '@rcf/envs';
export const URL = {
    MAIN: {
        [ENV.QA]: 'https://qa-dc.ru',
        [ENV.STAGE]: 'https://stage-dc.ru',
        [ENV.PROD]: 'https://domclick.ru'
    },
    AGENTS: {
        [ENV.QA]: 'https://agencies.qa-dc.ru/agents/:regionSlug',
        [ENV.STAGE]: 'https://agencies.qa-dc.ru/agents/:regionSlug',
        [ENV.PROD]: 'https://agencies.domclick.ru/agents/:regionSlug'
    },
    AGENCIES: {
        [ENV.QA]: 'https://agencies.qa-dc.ru/agencies/:regionSlug',
        [ENV.STAGE]: 'https://agencies.qa-dc.ru/agencies/:regionSlug',
        [ENV.PROD]: 'https://agencies.domclick.ru/agencies/:regionSlug'
    },
    TOP_AGENCIES: {
        [ENV.QA]: 'https://qa-dc.ru/top-agencies',
        [ENV.STAGE]: 'https://stage-dc.ru/top-agencies',
        [ENV.PROD]: 'https://domclick.ru/top-agencies'
    },
    TOP_DEVELOPERS: {
        [ENV.QA]: 'https://qa-dc.ru/top-developers',
        [ENV.STAGE]: 'https://stage-dc.ru/top-developers',
        [ENV.PROD]: 'https://domclick.ru/top-developers'
    },
    FAQ_CHOICE_AGENT: {
        [ENV.QA]: 'https://qa-dc.ru/faq/choice-agent',
        [ENV.STAGE]: 'https://stage-dc.ru/faq/choice-agent',
        [ENV.PROD]: 'https://domclick.rufaq/choice-agent'
    },
    FAQ_AGENT: {
        [ENV.QA]: 'https://qa-dc.ru/faq/agent',
        [ENV.STAGE]: 'https://stage-dc.ru/faq/agent',
        [ENV.PROD]: 'https://domclick.ru/faq/agent'
    },
    FAQ_APP: {
        [ENV.QA]: 'https://qa-dc.ru/faq/app',
        [ENV.STAGE]: 'https://stage-dc.ru/faq/app',
        [ENV.PROD]: 'https://domclick.ru/faq/app'
    },
    PRO_DASHBOARD_PROFILE: {
        [ENV.QA]: 'https://pro.qa-dc.ru/dashboard/profile',
        [ENV.STAGE]: 'https://pro.stage-dc.ru/dashboard/profile',
        [ENV.PROD]: 'https://pro.domclick.ru/dashboard/profile'
    },
    ANDROID: 'https://redirect.appmetrica.yandex.com/serve/100723119643185570',
    APP_STORE: 'https://redirect.appmetrica.yandex.com/serve/388953494754759547',
    PROMO_LEADER_AGENCY: 'https://promo.domclick.ru/leader-agency',
    PUBLIC_TERMS_OF_USE: 'https://domclick.ru/fs/public/docs/terms-of-use.pdf',
    PUBLIC_PRIVACY: 'https://domclick.ru/fs/public/docs/privacy.pdf'
};
