import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Paper } from '@front-core/box';
import { Button, Intent, Size } from '@front-core/button';
import { BreakPoints, useBreakpoint } from '@front-core/utils';
import { Fragment, memo, useEffect, useRef, useState } from 'react';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import OffersListHeader from './components/header';
import OffersListItem from './components/item';
import OffersListSkeleton from './components/skeleton';
import styles from './offersList.scss';
const OffersList = ({ offers, title, isLoading, sendItemAnalytics, pagination, onLoadMore }) => {
    const media = useAppSelector(state => state.ui.media);
    const [isShowMoreOffers, setIsShowMoreOffers] = useState(false);
    const buttonRef = useRef(null);
    const breakpoint = useBreakpoint(media === BreakPoints.MOBILE ? BreakPoints.MOBILE : BreakPoints.DESKTOP);
    const isMobileDevice = breakpoint === BreakPoints.MOBILE;
    useEffect(() => {
        if (isLoading && offers.length !== 0) {
            setTimeout(() => {
                var _a;
                (_a = buttonRef.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView({
                    block: 'center',
                    behavior: 'smooth'
                });
            }, 300);
        }
    }, [isLoading]);
    useEffect(() => {
        if (pagination) {
            setIsShowMoreOffers(pagination.hasMore);
        }
    }, [pagination]);
    return _jsxs(Paper, { tag: "section", className: styles.offersList, "data-sentry-element": "Paper", "data-sentry-component": "OffersList", "data-sentry-source-file": "index.tsx", children: [isLoading && offers.length === 0 ? _jsx(OffersListSkeleton, {}) : _jsxs(Fragment, { children: [_jsx(OffersListHeader, { count: pagination === null || pagination === void 0 ? void 0 : pagination.total, title: title }), _jsx("div", { className: styles.list, children: offers.map(offer => _jsx(OffersListItem, { offer: offer, sendItemAnalytics: sendItemAnalytics }, offer.id)) })] }), isShowMoreOffers ? _jsx(Button, { text: "\u041F\u043E\u043A\u0430\u0437\u0430\u0442\u044C \u0435\u0449\u0451", size: Size.Large, buttonRef: buttonRef, intent: Intent.Secondary, wrapperClassName: styles.showMore, onClick: onLoadMore, loading: isLoading, fluid: isMobileDevice }) : null] });
};
export default memo(OffersList);
