export var AgentOrder;
(function (AgentOrder) {
    AgentOrder["Recommendation"] = "recommendation";
    AgentOrder["Default"] = "default";
    AgentOrder["NewAgent"] = "new";
    AgentOrder["NewFreelancer"] = "freelancer";
    AgentOrder["TopRating"] = "rating";
    AgentOrder["TopDeals"] = "deals";
    AgentOrder["TopReviews"] = "reviews";
    AgentOrder["Individual"] = "individual";
})(AgentOrder || (AgentOrder = {}));
