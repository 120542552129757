import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AvatarIntent, AvatarType } from '@front-core/avatar';
import { LabelLargeMedium, LabelSmallRegular } from '@front-core/typography';
import { useMemo } from 'react';
import { generatePath } from 'react-router-dom';
import { PATH } from '@shared/config/constants';
import { StringHelper } from '@shared/lib/helpers/string';
import CustomLink from '@shared/ui/customLink';
import LayoutAvatar from '@shared/ui/layout/avatar';
import LayoutRating from '@shared/ui/layout/rating';
import { RatingType } from '@shared/ui/layout/rating/config';
import styles from './agenciesCardMobile.scss';
const AgenciesCardMobile = ({ agency, featureElement, onClickLink }) => {
    const url = useMemo(() => generatePath(PATH.AGENCY, {
        companyId: agency.slug
    }), [agency]);
    const getInfo = () => {
        var _a, _b, _c, _d, _e, _f;
        const votes = `${StringHelper.formatCurrency(((_a = agency.rating) === null || _a === void 0 ? void 0 : _a.votes) || 0)} ${StringHelper.getPluralForm(((_b = agency.rating) === null || _b === void 0 ? void 0 : _b.votes) || 0, ['отзыв', 'отзыва', 'отзывов'])}`;
        const deals = `${StringHelper.formatCurrency(((_c = agency.stats) === null || _c === void 0 ? void 0 : _c.dealsCount) || 0)} ${StringHelper.getPluralForm(((_d = agency.stats) === null || _d === void 0 ? void 0 : _d.dealsCount) || 0, ['сделка', 'сделки', 'сделок'])}`;
        const hasRatingVotes = ((_e = agency.rating) === null || _e === void 0 ? void 0 : _e.votes) && agency.rating.votes > 0;
        if (hasRatingVotes && agency.stats.dealsCount > 0) {
            return `${votes} и ${deals} за 12 мес.`;
        }
        if (!((_f = agency.rating) === null || _f === void 0 ? void 0 : _f.votes) && agency.stats.dealsCount > 0) {
            return `${votes} и ${deals} за 12 мес.`;
        }
        if (hasRatingVotes && !agency.stats.dealsCount) {
            return `${votes} и ${deals} за 12 мес.`;
        }
        return '0 отзывов и сделок за 12 мес.';
    };
    const getOffers = () => {
        var _a;
        const offersCount = ((_a = agency.stats) === null || _a === void 0 ? void 0 : _a.offersCount) || 0;
        return `${StringHelper.formatCurrency(offersCount)} ${StringHelper.getPluralForm(offersCount, ['объявление', 'объявления', 'объявлений'])}`;
    };
    return _jsxs("div", { className: styles.container, "data-sentry-component": "AgenciesCardMobile", "data-sentry-source-file": "index.tsx", children: [_jsx(CustomLink, { to: url, className: styles.link, onClick: onClickLink, "data-sentry-element": "CustomLink", "data-sentry-source-file": "index.tsx" }), _jsxs("div", { className: styles.inner, children: [_jsx("div", { className: styles.avatar, children: _jsx(LayoutAvatar, { name: agency.name, intent: (agency === null || agency === void 0 ? void 0 : agency.logoUrl) ? AvatarIntent.Tertiary : AvatarIntent.Secondary, type: AvatarType.Rect, imageSrc: (agency === null || agency === void 0 ? void 0 : agency.logoUrl) || undefined, "data-sentry-element": "LayoutAvatar", "data-sentry-source-file": "index.tsx" }) }), _jsxs("div", { className: styles.textContent, children: [_jsxs("div", { className: styles.header, children: [_jsx(LabelLargeMedium, { tag: "strong", className: styles.title, wrapper: false, "data-sentry-element": "LabelLargeMedium", "data-sentry-source-file": "index.tsx", children: agency.name }), agency.rating.avg > 0 ? _jsx(LayoutRating, { hasGrade: true, rating: agency.rating.avg, containerClassName: styles.rating, type: RatingType.Secondary }) : null] }), _jsx(LabelSmallRegular, { tag: "p", wrapper: false, className: styles.text, "data-sentry-element": "LabelSmallRegular", "data-sentry-source-file": "index.tsx", children: getInfo() }), _jsx(LabelSmallRegular, { tag: "p", wrapper: false, className: styles.text, "data-sentry-element": "LabelSmallRegular", "data-sentry-source-file": "index.tsx", children: getOffers() })] })] }), featureElement ? _jsx("div", { className: styles.button, children: featureElement }) : null] });
};
export default AgenciesCardMobile;
