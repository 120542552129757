import { AccessTokens } from '@shared/api/accessTokens';
import { baseApi } from '@shared/api/base';
import { ApiNames } from '@shared/api/names';
import { ppsApi } from '@shared/api/pps';
import vars from '@shared/config/vars';
import { ApiUrls } from '../config/apiUrls';
const { API_ENV } = vars();
export const _legacyCsiApi = ppsApi.injectEndpoints({
    endpoints: builder => ({
        agentCreateReview: builder.mutation({
            query: body => ({
                url: ApiUrls.AgentCreateReview.replace(':agentCasId', String(body.agentCasId)),
                method: 'PUT',
                headers: {
                    'x-access-token': AccessTokens[ApiNames.PPS][API_ENV]
                },
                body
            })
        })
    })
});
export const csiApi = baseApi.injectEndpoints({
    endpoints: builder => ({
        createAgentSurvey: builder.mutation({
            query: body => ({
                url: ApiUrls.Surveys,
                method: 'POST',
                body
            })
        }),
        createAgentsSurvey: builder.mutation({
            query: body => ({
                url: ApiUrls.Surveys,
                method: 'POST',
                body
            })
        }),
        createAgencySurvey: builder.mutation({
            query: body => ({
                url: ApiUrls.Surveys,
                method: 'POST',
                body
            })
        }),
        createAgenciesSurvey: builder.mutation({
            query: body => ({
                url: ApiUrls.Surveys,
                method: 'POST',
                body
            })
        })
    })
});
export const { useAgentCreateReviewMutation } = _legacyCsiApi;
export const { useCreateAgentSurveyMutation, useCreateAgentsSurveyMutation, useCreateAgencySurveyMutation, useCreateAgenciesSurveyMutation } = csiApi;
