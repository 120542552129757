import { sendAnalytics } from '@front-core/analytics-event';
import vars from '@shared/config/vars';
import { getUserDevice } from './getUserDevice';
import { ObjectHelper } from './helpers/object';
const { GROWTHBOOK_RESULT } = vars();
export const sendMetrics = (body) => {
    if (!SSR) {
        const source = getUserDevice();
        const data = {
            elementId: '',
            elementType: '',
            experimentGroup: JSON.stringify(GROWTHBOOK_RESULT),
            eventSense: {
                source
            }
        };
        for (const key in body) {
            if (body[key]) {
                data[key] = key === 'eventSense'
                    ? JSON.stringify({
                        ...ObjectHelper.convertCamelCaseToSnakeCase(body[key]),
                        source
                    })
                    : body[key];
            }
        }
        const convertedData = ObjectHelper.convertCamelCaseToSnakeCase(data);
        sendAnalytics(convertedData);
    }
};
