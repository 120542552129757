import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import ReviewsSkeleton from './components/skeleton';
import styles from './reviewsListSkeleton.scss';
const ReviewsListSkeleton = ({ length = 10 }) => {
    const skeletons = useMemo(() => Array.from({
        length
    }, (_value, index) => index + 1), [length]);
    return _jsx("div", { className: styles.reviewsListSkeleton, "data-sentry-component": "ReviewsListSkeleton", "data-sentry-source-file": "index.tsx", children: skeletons.map(item => _jsx(ReviewsSkeleton, {}, item)) });
};
export default ReviewsListSkeleton;
