import vars from '@shared/config/vars';
const { SERVICE_WORKER } = vars();
export default function sw() {
    if (('serviceWorker' in navigator) && SERVICE_WORKER === 'true') {
        window.addEventListener('load', () => {
            navigator.serviceWorker
                .register(`${FS_PATH}/service-worker.js`, { scope: '/' })
                .catch((error) => console.error(error));
        });
    }
}
