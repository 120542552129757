// Переход в рейтинги
export var TopMetricHashes;
(function (TopMetricHashes) {
    TopMetricHashes["ClickButtonTopDevelopers"] = "e0f61dbc333b889fc20ef7ac44230f75";
    TopMetricHashes["ClickButtonTopAgencies"] = "12bd37ea3c717f8276484f7e1fc92837";
})(TopMetricHashes || (TopMetricHashes = {}));
// WebView
export var WebViewMetricHashes;
(function (WebViewMetricHashes) {
    WebViewMetricHashes["Navigate"] = "4a42f169640a679eb2fb509d5a08a0d2";
})(WebViewMetricHashes || (WebViewMetricHashes = {}));
// Витрина агентов
export var AgentsMetricHashes;
(function (AgentsMetricHashes) {
    AgentsMetricHashes["ClickTabAgencies"] = "dc05caec0b3639078b3045a76426762d";
    AgentsMetricHashes["ClickSearchCity"] = "d3aea41dae0740c53ece6204c7ab4bce";
    AgentsMetricHashes["ClickSearchNameOrPhone"] = "48128e16db06e4a9f7a270bb5309f746";
    AgentsMetricHashes["InputSearchNameOrPhone"] = "82f4d6800645c774f5e8a925fda5192c";
    AgentsMetricHashes["ClickButtonWrite"] = "e8b49af7f0f00c37e1a82ec91f60d887";
    AgentsMetricHashes["ClickLinkAgentCard"] = "65d46b66969e581770c1641726e5aef9";
    AgentsMetricHashes["ClickAgentListPagination"] = "f94fdcd615a40c40f6cbdd5eeafe27";
    AgentsMetricHashes["ClickLinkRegions"] = "d133687b685d5acdbfafcebb5d34ebaa";
    AgentsMetricHashes["ScreenViewNotFoundNameOrPhone"] = "56a395c5960817e52940a57c14756125";
    AgentsMetricHashes["ScreenViewNotFoundCity"] = "30d5257134c3077b4585bd07b2bdbc9e";
    AgentsMetricHashes["ClickOpenFaqAccordion"] = "10ea22993a7a7b0fe5a97a9fb099bc82";
    AgentsMetricHashes["ClickHowChoiceAgentBanner"] = "12fdbcad52cbebb40b803e740b563c61";
    AgentsMetricHashes["ClickAgentHelpBanner"] = "e1ae8b0a5f42f5737451765fca77383c";
    AgentsMetricHashes["ClickAppBanner"] = "92db2184626510dfc89d20abc6cb4ed8";
    AgentsMetricHashes["ClickButtonDistrict"] = "139755541757942a150684ec9c3ef94e";
    AgentsMetricHashes["ClickButtonDealType"] = "37d801daf2a9a78aa40db088d4b73092";
})(AgentsMetricHashes || (AgentsMetricHashes = {}));
// Карточка агента
export var AgentMetricHashes;
(function (AgentMetricHashes) {
    AgentMetricHashes["ClickInputChat"] = "7c090420182e792b6fb3eb42d3196bd8";
    AgentMetricHashes["ClickButtonChat"] = "840dfe0e687cb5cdd4162e6e76b1e4a8";
    AgentMetricHashes["ClickButtonBuyHome"] = "afc6b86fc0f1bb142395991c92147582";
    AgentMetricHashes["ClickButtonSaleHome"] = "b79f32ecf8bee2131af112a46d966b1a";
    AgentMetricHashes["ClickButtonLeaseHome"] = "d78df51f138952cb6a84a787d71b798a";
    AgentMetricHashes["ClickButtonRentHome"] = "2b54772c78a048eb5d76728ae069ff34";
    AgentMetricHashes["ClickButtonWrite"] = "df66077f23e6c90b5b60f5cc84447012";
    AgentMetricHashes["ClickLinkAllAgents"] = "2ff9402e7c42faa6965bccc960b3d907";
    AgentMetricHashes["ClickLinkAgentSaleItem"] = "b193ab92a66e91bb17b38fe3acb04452";
    AgentMetricHashes["ClickButtonAgentSaleShowMore"] = "dbe310590ee612b937321a6616470fd";
    AgentMetricHashes["ClickLinkAgentRentItem"] = "8ba354cf64780eb135576a0e6a0bf5f4";
    AgentMetricHashes["ClickButtonAgentRentShowMore"] = "554749afeb3e1fdef45028318af3dcff";
    AgentMetricHashes["ClickButtonAgentAvatar"] = "2c4c91b875904ee9cf6a065f85bc568";
    AgentMetricHashes["ClickButtonAgentReviewCreate"] = "9d5b593a8fa3a4c055968b9ffbbd6049";
    AgentMetricHashes["ClickButtonAgentReviewEdit"] = "73449e1e7a5d8961fbc1d21383e6b0b0";
    AgentMetricHashes["ClickButtonAgentReviewDelete"] = "331385f3c7918c77992ed97d78f83f09";
    AgentMetricHashes["ClickButtonAgentReviewAnswerCreate"] = "548d05f9ff8e0c6a4569e597b1d0ce38";
    AgentMetricHashes["ClickButtonAgentReviewAnswerEdit"] = "b0bd6177e6b71d3071a11d5c62234dce";
    AgentMetricHashes["ClickButtonAgentReviewAnswerDelete"] = "115101a476f1fb511580623fa6815d97";
    AgentMetricHashes["SuccessResponseReview"] = "637c2015d6bfd5c969651aa0b77772cb";
    AgentMetricHashes["ClickOpenFaqAccordion"] = "e26f5022409012ddfc9e61fcb54fac60";
    AgentMetricHashes["ClickLinkAgency"] = "2499d5c8a8249d235a4282952179bd4d";
    AgentMetricHashes["ClickButtonCreateSubstitute"] = "7e42362190bb2190e0605cce9b7f07b1";
    AgentMetricHashes["ClickCallSubstitute"] = "558bf78b8abb33a2c31d303b6e9c0a65";
    AgentMetricHashes["ClickOrderReview"] = "ad2c2c8c5a690a2079dd562276c7d443";
    AgentMetricHashes["ClickFilterPeriodReview"] = "c1b62773f4a7e27bec629b0471de98da";
})(AgentMetricHashes || (AgentMetricHashes = {}));
// Витрина агентств
export var AgenciesMetricHashes;
(function (AgenciesMetricHashes) {
    AgenciesMetricHashes["ClickTabAgents"] = "50e1fd5172f7f00bfbb51edc5da870fa";
    AgenciesMetricHashes["ClickSearchCity"] = "fa657961d5a82942ed37b6edf92f0708";
    AgenciesMetricHashes["ClickSearchNameOrINN"] = "fb36b5556864f092640a019cd09d0dc6";
    AgenciesMetricHashes["InputSearchNameOrINN"] = "c676f8a8d54dab8a19dd6a0e30fddb54";
    AgenciesMetricHashes["ClickSort"] = "20d66b1010492a836ef41f488fc75e1d";
    AgenciesMetricHashes["ClickSortTopRating"] = "d4d11ac1517d17ff4cc92f7283f49982";
    AgenciesMetricHashes["ClickSortTopDeals"] = "d672cb527f5eb8729bb9979d041ee5ae";
    AgenciesMetricHashes["ClickSortTopReviews"] = "7894b62e36a30f1845599bb2ce68b74c";
    AgenciesMetricHashes["ClickLinkAgencyCard"] = "f219d89b878f5bde52096c2623832ce5";
    AgenciesMetricHashes["ClickAgenciesListPagination"] = "36e936d626fcace71b2cb5175fe7ac94";
    AgenciesMetricHashes["ClickLinkRegions"] = "d68a75cf86e3c0dac042e2016d70565";
    AgenciesMetricHashes["ClickOpenFaqAccordion"] = "afdaa590df9ff1c7c9bd3b4cbee2bf2a";
})(AgenciesMetricHashes || (AgenciesMetricHashes = {}));
// Карточка агентства
export var AgencyMetricHashes;
(function (AgencyMetricHashes) {
    AgencyMetricHashes["ClickLinkAllAgents"] = "79440d21d42fe3ec4e994265aad61a3e";
    AgencyMetricHashes["ClickSort"] = "4de6b7cbbdfd42d3a3dae4de29d45510";
    AgencyMetricHashes["ClickSortTopDeals"] = "3c9a5cda01b61b2674ab9efe743f2e2d";
    AgencyMetricHashes["ClickSortTopReviews"] = "f31bfc62f6a62cc09466259f513384fb";
    AgencyMetricHashes["ClickSortTopRating"] = "eae2e78652efceea39b8f2ff0c500069";
    AgencyMetricHashes["ClickInputSearchName"] = "383b0c4d40d8a7e703ab1a157424b148";
    AgencyMetricHashes["ClickLinkAgentCard"] = "46291732722dd4141469337b23c076a2";
    AgencyMetricHashes["ClickAgentListPagination"] = "8de5532f77116146b82f1d9226350abc";
    AgencyMetricHashes["ClickOfficesListCallButton"] = "777d00f0e4d48b175a430969da09cd75";
    AgencyMetricHashes["ClickOfficesListPagination"] = "9acea3bb339636b4e904068e738da510";
    AgencyMetricHashes["ClickLinkAgencySaleItem"] = "3a49a2196b8061a3005b7f5aec8d86ca";
    AgencyMetricHashes["ClickButtonAgencySaleShowMore"] = "8be2287b75aa2540144c8593654783e5";
    AgencyMetricHashes["ClickLinkAgencyRentItem"] = "5ff14f5d75acb864b5b3146bd184bfcc";
    AgencyMetricHashes["ClickButtonAgencyRentShowMore"] = "2a25f85faf1b691e63c36ef8776e4247";
    AgencyMetricHashes["ClickButtonCreateSubstitute"] = "b1279cc3a6f908203c09b2aed7cdaf17";
    AgencyMetricHashes["ClickCallSubstitute"] = "55763e7a79a16e27467acd5d8cb67d2e";
    AgencyMetricHashes["ClickOrderReview"] = "60bb35575db6f21e4d882cc66048babe";
    AgencyMetricHashes["ClickFilterPeriodReview"] = "6628b272c466a0e821ba0164d52b59aa";
})(AgencyMetricHashes || (AgencyMetricHashes = {}));
// Лендинг "Как выбрать риелтора"
export var FAQChoiceAgentHashes;
(function (FAQChoiceAgentHashes) {
    FAQChoiceAgentHashes["ClickBackButton"] = "3e784941d5e7a1a53e2b5879cd365680";
    FAQChoiceAgentHashes["ClickSearchAgentsButton"] = "ab47076e6828afea74ebba0a1fe898a8";
})(FAQChoiceAgentHashes || (FAQChoiceAgentHashes = {}));
// Лендинг "Как стать успешным риелтором"
export var FAQAgentHashes;
(function (FAQAgentHashes) {
    FAQAgentHashes["ClickBackButton"] = "71b77f5a45eb4b781b3692e929ea7eb";
    FAQAgentHashes["ClickEditProfileButton"] = "62d341e3b35310733fcd2cb0fceb1a50";
    FAQAgentHashes["ClickSearchAgentsButton"] = "b1bae097796017f254385cdcb9dff114";
})(FAQAgentHashes || (FAQAgentHashes = {}));
// Лендинг "Подберите риелтора в приложении"
export var FAQAppHashes;
(function (FAQAppHashes) {
    FAQAppHashes["ClickBackButton"] = "caf421f60493cd50c5df1c20d1706489";
    FAQAppHashes["ClickHeadAppStoreButton"] = "7af9b75d547e5df2cdf5af67ed190558";
    FAQAppHashes["ClickHeadAndroidButton"] = "b11985d05f5857d5337f978c9449e9ec";
    FAQAppHashes["ClickFooterAppStoreButton"] = "b90c097e9d8c336cc1b9b07d304ddd25";
    FAQAppHashes["ClickFooterAndroidButton"] = "d47ae6fba693cb0f4538cb7b8d52f0e1";
})(FAQAppHashes || (FAQAppHashes = {}));
