import ENV from '@rcf/envs';
import { ApiNames } from './names';
export const AccessTokens = {
    [ApiNames.BASE]: {
        [ENV.QA]: 'BkYvybLG3Io0U6hrLO6s31VeL4y3lhJf',
        [ENV.STAGE]: 'PIrN6fM6uifJdi2ZYb3vcvWghw2EY2bo',
        [ENV.PROD]: 'G5dClyxYADULFIxdDAvDJn3hlZAm1UbM'
    },
    [ApiNames.PPS]: {
        [ENV.QA]: 'DtnAOv1oeGeH8suqQ2p8s26s6K0QtJVY',
        [ENV.STAGE]: '8aLeRgVhssStEDAqwoLilIH6Y02UfjbC',
        [ENV.PROD]: 'QpPS71AnrlreG0FOLpvQLRLZK4ecnkPL'
    }
};
