import { createSlice } from '@reduxjs/toolkit';
import { MEDIA } from '@shared/config/constants';
import { Device } from '../config/device';
const initialState = {
    media: MEDIA.MOBILE,
    mismatch: false,
    userAgent: null,
    device: null,
    serverCookies: null,
    isRobot: null,
    isWebView: false,
    isNotFoundPage: false
};
const uiSlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        setMedia(state, action) {
            state.media = action.payload;
        },
        setUserAgent(state, action) {
            state.userAgent = action.payload;
        },
        setDevice(state, action) {
            var _a, _b;
            const isAndroid = Boolean((_a = action.payload) === null || _a === void 0 ? void 0 : _a.match(/^Android/));
            const isIOS = Boolean((_b = action.payload) === null || _b === void 0 ? void 0 : _b.match(/^iOS/));
            if (isAndroid || isIOS) {
                state.device = isAndroid ? Device.WebViewAndroid : Device.WebViewIos;
            }
            else {
                state.device = Device.Web;
            }
        },
        setCookies(state, action) {
            state.serverCookies = action.payload;
        },
        setMismatch(state, action) {
            state.mismatch = action.payload;
        },
        setIsWebView(state, action) {
            state.isWebView = action.payload;
        },
        setIsNotFoundPage(state, action) {
            state.isNotFoundPage = action.payload;
        },
        setIsRobot(state, action) {
            state.isRobot = action.payload;
        }
    }
});
export const { reducer, actions: { setMedia, setMismatch, setUserAgent, setCookies, setIsWebView, setDevice, setIsNotFoundPage, setIsRobot } } = uiSlice;
