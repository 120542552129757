import { jsx as _jsx } from "react/jsx-runtime";
import { BreakPoints, useBreakpoint } from '@front-core/utils';
import { AgentsMetricHashes } from '@shared/config/metrics';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import { sendMetrics } from '@shared/lib/sendMetrics';
import AgentsCardDesktop from './components/desktop';
import AgentsCardMobile from './components/mobile';
const AgentsCard = ({ agent, lastInCurrentRegion, primaryFeatureElement, secondaryFeatureElement, isSearchDistrict }) => {
    const media = useAppSelector(state => { var _a; return (_a = state.ui) === null || _a === void 0 ? void 0 : _a.media; });
    const queryParams = useAppSelector(state => state.agents.queryParams);
    const regionId = useAppSelector(state => { var _a; return (_a = state.geo) === null || _a === void 0 ? void 0 : _a.region.guid; });
    const breakpoint = useBreakpoint(media === BreakPoints.MOBILE ? BreakPoints.MOBILE : BreakPoints.DESKTOP);
    const isMobile = breakpoint === BreakPoints.MOBILE;
    const handleClickSendMetrics = () => {
        sendMetrics({
            eventType: 'click',
            elementId: AgentsMetricHashes.ClickLinkAgentCard,
            elementType: 'Link',
            eventSense: {
                regionId,
                queryParams
            }
        });
    };
    return isMobile ? _jsx(AgentsCardMobile, { agent: agent, lastInCurrentRegion: lastInCurrentRegion, onClickLink: handleClickSendMetrics, primaryFeatureElement: primaryFeatureElement, secondaryFeatureElement: secondaryFeatureElement, isSearchDistrict: isSearchDistrict, "data-sentry-element": "AgentsCardMobile", "data-sentry-component": "AgentsCard", "data-sentry-source-file": "index.tsx" }) : _jsx(AgentsCardDesktop, { agent: agent, lastInCurrentRegion: lastInCurrentRegion, onClickLink: handleClickSendMetrics, primaryFeatureElement: primaryFeatureElement, secondaryFeatureElement: secondaryFeatureElement, isSearchDistrict: isSearchDistrict, "data-sentry-element": "AgentsCardDesktop", "data-sentry-component": "AgentsCard", "data-sentry-source-file": "index.tsx" });
};
export default AgentsCard;
