export var ApiUrls;
(function (ApiUrls) {
    ApiUrls["Agencies"] = "/v1/agencies";
    ApiUrls["Agents"] = "/v1/agents";
    ApiUrls["AgentsFilters"] = "/v1/agents/filters";
    ApiUrls["Agency"] = "/v1/agencies/:companyId";
    ApiUrls["AgencyOffices"] = "/v1/agencies/:companyId/offices";
    ApiUrls["AgenciesReviews"] = "/v1/agencies/:companyId/reviews";
    ApiUrls["AgenciesRegions"] = "/v1/regions";
    ApiUrls["AgenciesSubstitutePhone"] = "/v1/agencies/:companyId/substitute-phone";
})(ApiUrls || (ApiUrls = {}));
