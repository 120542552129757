import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ObjectFit, Picture } from '@front-core/picture';
import { LabelLargeMedium, LabelSmallRegular } from '@front-core/typography';
import { BreakPoints, useBreakpoint } from '@front-core/utils';
import { ApiNames } from '@shared/api/names';
import { apiUrls } from '@shared/api/urls';
import vars from '@shared/config/vars';
import { StringHelper } from '@shared/lib/helpers/string';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import styles from './offersListItem.scss';
const { API_ENV } = vars();
const OffersListItem = ({ offer, sendItemAnalytics }) => {
    const media = useAppSelector(state => state.ui.media);
    const breakpoint = useBreakpoint(media === BreakPoints.MOBILE ? BreakPoints.MOBILE : BreakPoints.DESKTOP);
    const isMobile = breakpoint === BreakPoints.MOBILE;
    const src = offer.posterPath ? `${apiUrls[ApiNames.IMG][API_ENV]}${offer.posterPath}` : '';
    const handleClick = () => {
        if (sendItemAnalytics) {
            sendItemAnalytics(offer.id);
        }
    };
    return _jsxs("a", { className: styles.offersListItem, href: (offer === null || offer === void 0 ? void 0 : offer.link) || '', target: "_blank", rel: "noopener", onClick: handleClick, "data-sentry-component": "OffersListItem", "data-sentry-source-file": "index.tsx", children: [_jsx(Picture, { src: src, alt: "", width: isMobile ? 167.5 : 237, height: isMobile ? 128 : 213, objectFit: ObjectFit.Cover, className: styles.picture, errorText: "\u041D\u0435\u0442 \u0444\u043E\u0442\u043E", "data-sentry-element": "Picture", "data-sentry-source-file": "index.tsx" }), _jsxs("div", { className: styles.description, children: [offer.price ? _jsx(LabelLargeMedium, { tag: "span", wrapper: false, className: styles.price, children: `${StringHelper.formatCurrency(offer.price)} ₽` }) : null, _jsxs(LabelSmallRegular, { tag: "p", wrapper: false, className: styles.specifications, "data-sentry-element": "LabelSmallRegular", "data-sentry-source-file": "index.tsx", children: [offer.rooms ? _jsx(LabelSmallRegular, { tag: "span", wrapper: false, className: styles.specificationsItem, children: `${offer.rooms}-комн.` }) : null, offer.area ? _jsx(LabelSmallRegular, { tag: "span", wrapper: false, className: styles.specificationsItem, children: `${offer.area} м²` }) : null, offer.floor ? _jsx(LabelSmallRegular, { tag: "span", wrapper: false, className: styles.specificationsItem, children: `${offer.floor} эт.` }) : null] }), offer.addressFull ? _jsx(LabelSmallRegular, { tag: "span", wrapper: false, className: styles.address, children: offer.addressFull }) : null] })] });
};
export default OffersListItem;
