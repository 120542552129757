const Regex = {
    SnakeCase: /_([a-z])/g,
    CamelCase: /([a-z])([A-Z])/g
};
const convertSnakeCaseToCamelCase = (obj) => {
    const newObj = {};
    for (const key in obj) {
        if (Object.hasOwnProperty.call(obj, key)) {
            let newKey = key.replace(Regex.SnakeCase, (match, letter) => letter.toUpperCase());
            newKey = newKey.charAt(0).toLowerCase() + newKey.slice(1);
            const value = obj[key];
            if (Array.isArray(value)) {
                const hasNonObjectValues = value.some(item => typeof item !== 'object' || item === null);
                // eslint-disable-next-line max-depth
                if (hasNonObjectValues) {
                    newObj[newKey] = value;
                }
                else {
                    newObj[newKey] = value.map(convertSnakeCaseToCamelCase);
                }
            }
            else if (typeof value === 'object' && value !== null) {
                newObj[newKey] = convertSnakeCaseToCamelCase(value);
            }
            else {
                newObj[newKey] = value;
            }
        }
    }
    return newObj;
};
const convertCamelCaseToSnakeCase = (obj) => {
    const newObj = {};
    for (const key in obj) {
        if (Object.hasOwnProperty.call(obj, key)) {
            const newKey = key.replace(Regex.CamelCase, '$1_$2').toLowerCase();
            const value = obj[key];
            if (Array.isArray(value)) {
                const hasNonObjectValues = value.some(item => typeof item !== 'object' || item === null);
                // eslint-disable-next-line max-depth
                if (hasNonObjectValues) {
                    newObj[newKey] = value;
                }
                else {
                    newObj[newKey] = value.map(convertCamelCaseToSnakeCase);
                }
            }
            else if (typeof value === 'object' && value !== null) {
                newObj[newKey] = convertCamelCaseToSnakeCase(value);
            }
            else {
                newObj[newKey] = value;
            }
        }
    }
    return newObj;
};
const convertFormData = (data) => {
    const currentData = {};
    for (const [key, value] of data.entries()) {
        currentData[key] = value;
    }
    return currentData;
};
const isEmpty = (data) => Object.keys(data).length === 0;
export const ObjectHelper = {
    convertSnakeCaseToCamelCase,
    convertCamelCaseToSnakeCase,
    convertFormData,
    isEmpty
};
