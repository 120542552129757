import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    modals: {}
};
const reviewsSlice = createSlice({
    name: 'reviews',
    initialState,
    reducers: {
        toggleModals(state, action) {
            if (action.payload in state.modals) {
                delete state.modals[action.payload];
            }
            else {
                state.modals[action.payload] = true;
            }
        }
    }
});
export const { reducer, actions: { toggleModals } } = reviewsSlice;
