import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Paper } from '@front-core/box';
import { Button, Intent } from '@front-core/button';
import { EmptyScreen, ErrorCode } from '@front-core/empty-screen';
import { isWebView } from '@front-core/utils';
import { clsx } from 'clsx';
import { Helmet } from 'react-helmet-async';
import { URL } from '@shared/config/urls';
import vars from '@shared/config/vars';
import styles from './notFound.scss';
const { API_ENV } = vars();
const NotFoundPage = () => _jsxs(Paper, { className: clsx(styles.page, {
        [styles.isWebView]: isWebView()
    }), "data-sentry-element": "Paper", "data-sentry-component": "NotFoundPage", "data-sentry-source-file": "notFound.tsx", children: [_jsx(Helmet, { "data-sentry-element": "Helmet", "data-sentry-source-file": "notFound.tsx", children: _jsx("title", { children: "\u041E\u0448\u0438\u0431\u043A\u0430 404 - \u0414\u043E\u043C\u043A\u043B\u0438\u043A" }) }), _jsx(EmptyScreen, { image: ErrorCode.NotFound, title: "404 \u0421\u0442\u0440\u0430\u043D\u0438\u0446\u0430 \u043D\u0435 \u043D\u0430\u0439\u0434\u0435\u043D\u0430", text: "\u0412 \u0430\u0434\u0440\u0435\u0441\u0435 \u0432\u043E\u0437\u043C\u043E\u0436\u043D\u0430 \u043E\u0448\u0438\u0431\u043A\u0430 \u0438\u043B\u0438 \u0441\u0442\u0440\u0430\u043D\u0438\u0446\u0430 \u0443\u0434\u0430\u043B\u0435\u043D\u0430", buttons: !isWebView() ? [_jsx(Button, { text: "\u041D\u0430\u043F\u0438\u0441\u0430\u0442\u044C \u0432 \u043F\u043E\u0434\u0434\u0435\u0440\u0436\u043A\u0443", href: "mailto:help@domclick.ru", intent: Intent.Secondary }, "contact-support"), _jsx(Button, { text: "\u041F\u0435\u0440\u0435\u0439\u0442\u0438 \u043D\u0430 \u0433\u043B\u0430\u0432\u043D\u0443\u044E", href: URL.MAIN[API_ENV] }, "go-home")] : undefined, "data-sentry-element": "EmptyScreen", "data-sentry-source-file": "notFound.tsx" }), isWebView() ? _jsxs("div", { className: styles.buttons, children: [_jsx(Button, { text: "\u041D\u0430\u043F\u0438\u0441\u0430\u0442\u044C \u0432 \u043F\u043E\u0434\u0434\u0435\u0440\u0436\u043A\u0443", href: "mailto:help@domclick.ru", intent: Intent.Secondary }, "contact-support"), _jsx(Button, { text: "\u041F\u0435\u0440\u0435\u0439\u0442\u0438 \u043D\u0430 \u0433\u043B\u0430\u0432\u043D\u0443\u044E", href: URL.MAIN[API_ENV] }, "go-home")] }) : null] });
export default NotFoundPage;
