import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AvatarSize } from '@front-core/avatar';
import { LabelStandardMedium, LabelSmallRegular } from '@front-core/typography';
import ClampedText from '@shared/ui/clampedText';
import LayoutAvatar from '@shared/ui/layout/avatar';
import styles from './reviewsCardAnswerToReview.scss';
const ReviewsCardAnswerToReview = ({ answer, answerToReviewDropDown }) => {
    var _a, _b, _c;
    return _jsxs("div", { className: styles.container, "data-sentry-component": "ReviewsCardAnswerToReview", "data-sentry-source-file": "index.tsx", children: [_jsx(LayoutAvatar, { size: AvatarSize.Small, name: (_a = answer === null || answer === void 0 ? void 0 : answer.author) === null || _a === void 0 ? void 0 : _a.name, imageSrc: ((_b = answer.author) === null || _b === void 0 ? void 0 : _b.avatarUrl) || undefined, wrapperClassName: styles.avatar, "data-sentry-element": "LayoutAvatar", "data-sentry-source-file": "index.tsx" }), _jsxs("div", { className: styles.header, children: [_jsx(LabelStandardMedium, { tag: "strong", wrapper: false, className: styles.titleText, "data-sentry-element": "LabelStandardMedium", "data-sentry-source-file": "index.tsx", children: (_c = answer === null || answer === void 0 ? void 0 : answer.author) === null || _c === void 0 ? void 0 : _c.name }), _jsxs(LabelSmallRegular, { tag: "span", wrapper: false, className: styles.subTitle, "data-sentry-element": "LabelSmallRegular", "data-sentry-source-file": "index.tsx", children: ["\u0420\u0438\u0435\u043B\u0442\u043E\u0440 \u043E\u0442\u0432\u0435\u0442\u0438\u043B ", answer.createdAt] })] }), _jsxs("div", { className: styles.replyToReviewContainer, children: [_jsx(ClampedText, { buttonText: "\u041F\u043E\u043A\u0430\u0437\u0430\u0442\u044C \u043F\u043E\u043B\u043D\u043E\u0441\u0442\u044C\u044E", text: answer.message, "data-sentry-element": "ClampedText", "data-sentry-source-file": "index.tsx" }), answerToReviewDropDown] })] });
};
export default ReviewsCardAnswerToReview;
