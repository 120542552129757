export const mapQueryParams = (payload, limit) => {
    let stateQueryParams = {};
    for (const key in payload) {
        if (payload[key] !== undefined) {
            if (key === 'offset') {
                const currentKey = String(key);
                delete stateQueryParams[currentKey];
                stateQueryParams = {
                    ...stateQueryParams,
                    page: (payload[currentKey] / limit) > 0 ? (payload[currentKey] / limit) + 1 : null
                };
            }
            else {
                stateQueryParams[key] = payload[key];
            }
        }
    }
    return stateQueryParams;
};
