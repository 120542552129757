import { baseApi } from '@shared/api/base';
import { ApiUrls } from '../config/apiUrls';
export const geoApi = baseApi.injectEndpoints({
    endpoints: builder => ({
        getRegion: builder.query({
            query: regionId => ({
                url: ApiUrls.Region.replace(':regionId', regionId)
            })
        }),
        getDistricts: builder.query({
            query: params => ({
                url: ApiUrls.Districts,
                params
            })
        }),
        getDistrict: builder.query({
            query: districtGuid => ({
                url: ApiUrls.District.replace(':districtGuid', districtGuid)
            })
        })
    })
});
export const { useLazyGetRegionQuery, useLazyGetDistrictsQuery, useGetDistrictsQuery } = geoApi;
