import { uiSlice } from '@entities/ui';
import { getMedia } from '@shared/lib/getMedia';
export const uiListener = (startListening) => {
    startListening({
        actionCreator: uiSlice.setMedia,
        effect: (action, listenerApi) => {
            listenerApi.cancelActiveListeners();
            if (!SSR) {
                const clientMedia = getMedia();
                const serverMedia = action.payload;
                const value = {
                    media: clientMedia,
                    mismatch: clientMedia !== serverMedia
                };
                if (value.mismatch) {
                    listenerApi.dispatch(uiSlice.setMismatch(value.mismatch));
                }
            }
        }
    });
};
