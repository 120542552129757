import { baseApi } from '@shared/api/base';
import { ApiUrls } from '../config/apiUrls';
const LimitConfig = {
    AgencyDeals: 5,
    AgentDeals: 5
};
export const dealsApi = baseApi.injectEndpoints({
    endpoints: builder => ({
        getAgentDeals: builder.query({
            query: params => ({
                url: ApiUrls.Deals,
                params: {
                    ...params,
                    limit: LimitConfig.AgentDeals
                }
            })
        }),
        getAgencyDeals: builder.query({
            query: params => ({
                url: ApiUrls.Deals,
                params: {
                    ...params,
                    limit: LimitConfig.AgencyDeals
                }
            })
        })
    })
});
export const { useLazyGetAgencyDealsQuery, useLazyGetAgentDealsQuery } = dealsApi;
