export var RatingType;
(function (RatingType) {
    RatingType["Primary"] = "primary";
    RatingType["Secondary"] = "secondary";
})(RatingType || (RatingType = {}));
export var RatingFontSize;
(function (RatingFontSize) {
    RatingFontSize["Small"] = "sm";
    RatingFontSize["Medium"] = "md";
})(RatingFontSize || (RatingFontSize = {}));
