import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Intent, Size } from '@front-core/button';
import { Tabs } from '@front-core/tabs';
import { useBreakpoint, BreakPoints } from '@front-core/utils';
import { useMemo, useCallback } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { agenciesSlice } from '@entities/agencies';
import { agentsSlice } from '@entities/agents';
import { PATH, TOP } from '@shared/config/constants';
import { AgentsMetricHashes, AgenciesMetricHashes, TopMetricHashes } from '@shared/config/metrics';
import { URL } from '@shared/config/urls';
import vars from '@shared/config/vars';
import { useAppDispatch } from '@shared/lib/hooks/useAppDispatch';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import { sendMetrics } from '@shared/lib/sendMetrics';
import CustomLink from '@shared/ui/customLink';
import styles from './navigationTabs.scss';
const { API_ENV } = vars();
const links = [{
        text: TOP.AGENCIES,
        elementId: TopMetricHashes.ClickButtonTopAgencies,
        link: URL.TOP_AGENCIES[API_ENV]
    }, {
        text: TOP.DEVELOPERS,
        elementId: TopMetricHashes.ClickButtonTopDevelopers,
        link: URL.TOP_DEVELOPERS[API_ENV]
    }];
const NavigationTabs = () => {
    const media = useAppSelector(state => { var _a; return (_a = state.ui) === null || _a === void 0 ? void 0 : _a.media; });
    const regionId = useAppSelector(state => { var _a; return (_a = state.geo) === null || _a === void 0 ? void 0 : _a.region.guid; });
    const regionSlug = useAppSelector(state => { var _a; return (_a = state.geo) === null || _a === void 0 ? void 0 : _a.slug; });
    const agentsQueryParams = useAppSelector(state => { var _a; return (_a = state.agents) === null || _a === void 0 ? void 0 : _a.queryParams; });
    const agenciesQueryParams = useAppSelector(state => { var _a; return (_a = state.agencies) === null || _a === void 0 ? void 0 : _a.queryParams; });
    const urlPathPattern = useAppSelector(state => state.url.path.pattern);
    const isWebView = useAppSelector(state => { var _a; return (_a = state.ui) === null || _a === void 0 ? void 0 : _a.isWebView; });
    const dispatch = useAppDispatch();
    const breakpoint = useBreakpoint(media === BreakPoints.MOBILE ? BreakPoints.MOBILE : BreakPoints.DESKTOP);
    const navigate = useNavigate();
    const isDesktop = breakpoint === BreakPoints.TABLET_LANDSCAPE || breakpoint === BreakPoints.DESKTOP;
    const agentsPath = generatePath(PATH.AGENTS, {
        regionSlug
    });
    const agenciesPath = generatePath(PATH.AGENCIES, {
        regionSlug
    });
    const tabs = useMemo(() => [{
            label: 'Риелторы',
            value: PATH.AGENTS,
            routerLink: _jsx(CustomLink, { to: agentsPath, children: "\u0420\u0438\u0435\u043B\u0442\u043E\u0440\u044B" })
        }, {
            label: 'Агентства недвижимости',
            value: PATH.AGENCIES,
            routerLink: _jsx(CustomLink, { to: agenciesPath, children: "\u0410\u0433\u0435\u043D\u0442\u0441\u0442\u0432\u0430 \u043D\u0435\u0434\u0432\u0438\u0436\u0438\u043C\u043E\u0441\u0442\u0438" })
        }], [regionSlug]);
    const webViewTabs = useMemo(() => [{
            label: 'Риелторы',
            value: PATH.AGENTS
        }, {
            label: 'Агентства недвижимости',
            value: PATH.AGENCIES
        }], [regionSlug]);
    const updateAgencies = () => {
        dispatch(agenciesSlice.putQueryParams({
            regionId
        }));
    };
    const updateAgents = () => {
        dispatch(agentsSlice.putQueryParams({
            regionId
        }));
    };
    const handleChangeTab = useCallback((tab) => {
        if (urlPathPattern !== tab) {
            switch (tab) {
                case PATH.AGENCIES:
                    {
                        updateAgencies();
                        if (isWebView) {
                            navigate(agenciesPath, {
                                replace: true
                            });
                        }
                        sendMetrics({
                            eventType: 'click',
                            elementId: AgentsMetricHashes.ClickTabAgencies,
                            elementType: 'Tabs'
                        });
                        break;
                    }
                case PATH.AGENTS:
                    {
                        updateAgents();
                        if (isWebView) {
                            navigate(agentsPath, {
                                replace: true
                            });
                        }
                        sendMetrics({
                            eventType: 'click',
                            elementId: AgenciesMetricHashes.ClickTabAgents,
                            elementType: 'Tabs'
                        });
                        break;
                    }
                default:
                    break;
            }
        }
    }, [urlPathPattern, regionSlug, regionId, agenciesQueryParams, agentsQueryParams]);
    const handleClick = (elementId) => {
        sendMetrics({
            eventType: 'click',
            elementId,
            elementType: 'Button'
        });
    };
    return _jsxs("div", { className: styles.container, "data-sentry-component": "NavigationTabs", "data-sentry-source-file": "index.tsx", children: [_jsx("div", { className: styles.tabs, children: _jsx(Tabs, { tabs: isWebView ? webViewTabs : tabs, value: urlPathPattern, onValueChange: handleChangeTab, "data-sentry-element": "Tabs", "data-sentry-source-file": "index.tsx" }) }), isDesktop ? _jsx("div", { className: styles.links, children: links.map(link => _jsx(Button, { href: link.link, target: "_blank", text: link.text, intent: Intent.TransparentSecondary, size: Size.Small, onClick: () => handleClick(link.elementId) }, link.text)) }) : null] });
};
export default NavigationTabs;
