import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import { Unicode } from '@shared/config/unicode';
dayjs.locale('ru');
const format = (dateTime, formatTemplate, hasBreakSpace = false) => {
    const date = dayjs(dateTime);
    const isValidDate = date.isValid();
    if (isValidDate) {
        const formattedDate = date.format(formatTemplate);
        return hasBreakSpace ? formattedDate : formattedDate.replace(/ /g, Unicode.NoBreakSpace);
    }
    return '';
};
export const DateTimeHelper = {
    format
};
