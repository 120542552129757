import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    casId: null,
    casIdSigned: null
};
const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setCasIdSigned(state, action) {
            state.casIdSigned = action.payload;
        },
        logIn(state, action) {
            state.casId = action.payload || null;
        },
        logOut(state) {
            state.casId = null;
        }
    }
});
export const { reducer, actions: { logIn, logOut, setCasIdSigned } } = userSlice;
