import { StringHelper } from '@shared/lib/helpers/string';
// Получение разницы дат в формате YY | MM | DD & год | месяц | день
export const getDateDifferenceString = (date, currentDate) => {
    if (!date) {
        return '';
    }
    const parsedDate = typeof date === 'string' ? new Date(Date.parse(date)) : date;
    let now;
    if (!currentDate) {
        now = new Date();
    }
    else if (typeof currentDate === 'string') {
        now = new Date(Date.parse(currentDate));
    }
    else {
        now = currentDate;
    }
    if (Number.isNaN(parsedDate.getTime()) || Number.isNaN(now.getTime())) {
        return 'Неверная дата';
    }
    let yearsDifference = now.getFullYear() - parsedDate.getFullYear();
    let monthsDifference = now.getMonth() - parsedDate.getMonth();
    let daysDifference = now.getDate() - parsedDate.getDate();
    if (daysDifference < 0) {
        monthsDifference -= 1;
        daysDifference += new Date(now.getFullYear(), now.getMonth(), 0).getDate();
    }
    if (monthsDifference < 0) {
        yearsDifference -= 1;
        monthsDifference += 12;
    }
    if (yearsDifference >= 1) {
        const yearsFormat = StringHelper.getPluralForm(yearsDifference, ['год', 'года', 'лет'], true);
        if (monthsDifference > 0) {
            return `${yearsFormat} ${monthsDifference} мес.`;
        }
        return yearsFormat;
    }
    if (monthsDifference >= 1) {
        return StringHelper.getPluralForm(monthsDifference, ['месяц', 'месяца', 'месяцев'], true);
    }
    return StringHelper.getPluralForm(daysDifference, ['день', 'дня', 'дней'], true);
};
