import ENV from '@rcf/envs';
export default function vars() {
    if (SSR) {
        const { API_ENV, POLYFILLS_URL, GTM, YM, YM_DC_COUNTER, PROMETHEUS_LABEL, SERVICE_WORKER, TG_CHAT_ID, TG_BOT_TOKEN, SENTRY_DSN, SENTRY_URL, PTS_AGENCIES_API_URL, GROWTHBOOK_CLIENT_KEY, GROWTHBOOK_API_HOST, APP_NAMESPACE } = process.env;
        return {
            API_ENV: API_ENV || ENV.PROD,
            APP_NAMESPACE: APP_NAMESPACE || 'pts-agencies-front-ssr',
            POLYFILLS_URL,
            GTM: GTM || 'false',
            YM: YM || 'false',
            YM_DC_COUNTER: YM_DC_COUNTER ? Number(YM_DC_COUNTER) : 33709249,
            PROMETHEUS_LABEL,
            SERVICE_WORKER: SERVICE_WORKER || 'false',
            TG_CHAT_ID,
            TG_BOT_TOKEN,
            SENTRY_DSN,
            SENTRY_URL,
            PTS_AGENCIES_API_URL,
            GROWTHBOOK_CLIENT_KEY,
            GROWTHBOOK_API_HOST
        };
    }
    const { API_ENV, APP_NAMESPACE, SERVICE_WORKER, SENTRY_DSN, GROWTHBOOK_RESULT, GROWTHBOOK_CLIENT_KEY, GROWTHBOOK_API_HOST, GROWTHBOOK_DATA, YM_DC_COUNTER } = window.__APP_VARS__;
    return {
        API_ENV,
        APP_NAMESPACE,
        SERVICE_WORKER,
        SENTRY_DSN,
        GROWTHBOOK_RESULT,
        GROWTHBOOK_CLIENT_KEY,
        GROWTHBOOK_API_HOST,
        GROWTHBOOK_DATA,
        YM_DC_COUNTER
    };
}
