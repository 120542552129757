import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link, Target, Theme } from '@front-core/link';
import { Picture } from '@front-core/picture';
import { Tooltip } from '@front-core/tooltip';
import { useBreakpoint, BreakPoints } from '@front-core/utils';
import { getPartnershipLevel } from '@entities/agencies/lib/getPartnershipLevel';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import CustomLink from '@shared/ui/customLink';
import styles from './agenciesOfficeCard.scss';
const AgenciesOfficeCard = ({ office, featureElement }) => {
    const media = useAppSelector(state => state.ui.media);
    const isWebView = useAppSelector(state => state.ui.isWebView);
    const breakpoint = useBreakpoint(media === BreakPoints.MOBILE ? BreakPoints.MOBILE : BreakPoints.DESKTOP);
    const isMobile = breakpoint === BreakPoints.MOBILE || breakpoint === BreakPoints.TABLET_PORTRAIT;
    const partnershipLevel = getPartnershipLevel(office.partnershipLevel);
    return _jsx("div", { className: styles.container, "data-sentry-component": "AgenciesOfficeCard", "data-sentry-source-file": "index.tsx", children: _jsxs("div", { className: styles.body, children: [_jsxs("div", { className: styles.inner, children: [_jsx("strong", { className: styles.title, children: office.name }), _jsx("p", { className: styles.address, children: office.addressFull }), office.partnershipLevel && partnershipLevel ? _jsx(Tooltip, { text: _jsxs("div", { className: styles.tooltipText, children: [partnershipLevel.tooltip.text, _jsx(Link, { text: "\u041F\u043E\u0434\u0440\u043E\u0431\u043D\u0435\u0435", theme: Theme.Inverse, className: styles.tooltipLink, routerLink: _jsx(CustomLink, { to: partnershipLevel.tooltip.href, target: isWebView ? undefined : Target.Blank, children: "\u041F\u043E\u0434\u0440\u043E\u0431\u043D\u0435\u0435" }) })] }), mobile: isMobile, defaultBreakpoint: breakpoint, children: _jsxs("div", { className: styles.partnerShip, children: [_jsx(Picture, { alt: "", src: partnershipLevel.image.src, src2x: partnershipLevel.image.src2x, webpSrc: partnershipLevel.image.webpSrc, webpSrc2x: partnershipLevel.image.webpSrc2x, width: 24, height: 24 }), _jsx("span", { children: partnershipLevel.label })] }) }) : null] }), featureElement] }) });
};
export default AgenciesOfficeCard;
