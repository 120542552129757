export var MEDIA;
(function (MEDIA) {
    MEDIA["DESKTOP"] = "desktop";
    MEDIA["TABLET"] = "tablet";
    MEDIA["MOBILE"] = "mobile";
})(MEDIA || (MEDIA = {}));
export var BREAKPOINTS;
(function (BREAKPOINTS) {
    BREAKPOINTS[BREAKPOINTS["TABLET"] = 768] = "TABLET";
    BREAKPOINTS[BREAKPOINTS["DESKTOP"] = 1200] = "DESKTOP";
})(BREAKPOINTS || (BREAKPOINTS = {}));
export var TOP;
(function (TOP) {
    TOP["AGENCIES"] = "\u0422\u043E\u043F-100 \u0430\u0433\u0435\u043D\u0442\u0441\u0442\u0432 \u043D\u0435\u0434\u0432\u0438\u0436\u0438\u043C\u043E\u0441\u0442\u0438";
    TOP["DEVELOPERS"] = "\u0422\u043E\u043F-100 \u0437\u0430\u0441\u0442\u0440\u043E\u0439\u0449\u0438\u043A\u043E\u0432";
})(TOP || (TOP = {}));
export var PATH;
(function (PATH) {
    PATH["AGENTS"] = "/agents/:regionSlug?";
    PATH["AGENT"] = "/agent/:agentCasId";
    PATH["AGENCIES"] = "/agencies/:regionSlug?";
    PATH["AGENCY"] = "/agency/:companyId";
    PATH["FAQ_CHOICE_AGENT"] = "/faq/choice-agent";
    PATH["FAQ_AGENT"] = "/faq/agent";
    PATH["FAQ_APP"] = "/faq/app";
    PATH["NOT_FOUND"] = "*";
})(PATH || (PATH = {}));
export var GEO;
(function (GEO) {
    GEO["SLUG"] = "moskva";
    GEO["NAME"] = "\u041C\u043E\u0441\u043A\u0432\u0430";
    GEO["NAME_LOC_IN"] = "\u0432\u00A0\u041C\u043E\u0441\u043A\u0432\u0435";
    GEO["GUID"] = "1d1463ae-c80f-4d19-9331-a1b68a85b553";
})(GEO || (GEO = {}));
export var ROUTES;
(function (ROUTES) {
    ROUTES["AGENTS"] = "agents";
    ROUTES["AGENT"] = "agent";
    ROUTES["AGENCY"] = "agency";
    ROUTES["AGENCIES"] = "agencies";
    ROUTES["FAQ_CHOICE_AGENT"] = "faq_choice_agent";
    ROUTES["FAQ_AGENT"] = "faq_agent";
    ROUTES["FAQ_APP"] = "faq_app";
    ROUTES["NOT_FOUND"] = "not_found";
})(ROUTES || (ROUTES = {}));
