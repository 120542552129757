import { createApi } from '../createApi';
import { getBaseQuery } from '../getBaseQuery';
import { ApiNames } from '../names';
import { baseTagTypes } from './tags';
export const baseApi = createApi({
    tagTypes: baseTagTypes,
    reducerPath: 'apiBase',
    baseQuery: getBaseQuery(ApiNames.BASE),
    endpoints: () => ({})
});
