export const AGENTS_TAG = 'AGENTS_TAG';
export const AGENT_TAG = 'AGENT_TAG';
export const AGENTS_REGIONS_TAG = 'AGENTS_REGIONS_TAG';
export const AGENTS_REVIEWS_TAG = 'AGENTS_REVIEWS_TAG';
export const AGENCIES_TAG = 'AGENCIES_TAG';
export const AGENCIES_REGIONS_TAG = 'AGENCIES_REGIONS_TAG';
export const AGENCY_TAG = 'AGENCY_TAG';
export const baseTagTypes = [
    AGENTS_TAG,
    AGENT_TAG,
    AGENTS_REGIONS_TAG,
    AGENTS_REVIEWS_TAG,
    AGENCIES_TAG,
    AGENCY_TAG,
    AGENCIES_REGIONS_TAG
];
