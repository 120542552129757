export var ApiUrls;
(function (ApiUrls) {
    ApiUrls["Agent"] = "/v1/agents/:agentCasId";
    ApiUrls["Agents"] = "/v1/agents";
    ApiUrls["AgentsCount"] = "/v1/agents/count";
    ApiUrls["AgentsFilters"] = "/v1/agents/filters";
    ApiUrls["AgentRegions"] = "/v1/regions";
    ApiUrls["AgentReviews"] = "/v1/agents/:agentCasId/reviews";
    ApiUrls["AgentReviewsMe"] = "/v2/agents/:agentCasId/reviews/me";
    ApiUrls["AgentDealsStats"] = "/v1/agents/:agentCasId/deals-stats";
    ApiUrls["AgentChat"] = "/v1/agents/:agentCasId/chat";
    ApiUrls["AgentsSubstitutePhone"] = "/v1/agents/:agentCasId/substitute-phone";
})(ApiUrls || (ApiUrls = {}));
