import { createSlice } from '@reduxjs/toolkit';
import { offersApi } from './api';
const initialState = {
    agent: {
        sales: {
            list: [],
            pagination: null
        },
        rents: {
            list: [],
            pagination: null
        }
    },
    agency: {
        sales: {
            list: [],
            pagination: null
        },
        rents: {
            list: [],
            pagination: null
        }
    }
};
export const offersSlice = createSlice({
    name: 'offers',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addMatcher(offersApi.endpoints.getAgentOffersSale.matchFulfilled, (state, { payload }) => {
            if (payload.result) {
                state.agent.sales.list = payload.result;
                state.agent.sales.pagination = (payload === null || payload === void 0 ? void 0 : payload.pagination) || null;
            }
        });
        builder.addMatcher(offersApi.endpoints.getAgentOffersRent.matchFulfilled, (state, { payload }) => {
            if (payload.result) {
                state.agent.rents.list = payload.result;
                state.agent.rents.pagination = (payload === null || payload === void 0 ? void 0 : payload.pagination) || null;
            }
        });
        builder.addMatcher(offersApi.endpoints.getAgencyOffersSale.matchFulfilled, (state, { payload }) => {
            if (payload.result) {
                state.agency.sales.list = payload.result;
                state.agency.sales.pagination = (payload === null || payload === void 0 ? void 0 : payload.pagination) || null;
            }
        });
        builder.addMatcher(offersApi.endpoints.getAgencyOffersRent.matchFulfilled, (state, { payload }) => {
            if (payload.result) {
                state.agency.rents.list = payload.result;
                state.agency.rents.pagination = (payload === null || payload === void 0 ? void 0 : payload.pagination) || null;
            }
        });
    }
});
export const { reducer } = offersSlice;
