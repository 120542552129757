export const getShortName = (fullName) => {
    if (fullName.length < 2) {
        return fullName.join(' ');
    }
    const [lastName, firstName] = fullName;
    if (firstName) {
        return `${firstName} ${lastName.charAt(0)}.`;
    }
    return '';
};
