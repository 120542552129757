export var AgentSubtypes;
(function (AgentSubtypes) {
    AgentSubtypes["CompanyHead"] = "company_head";
    AgentSubtypes["OfficeHead"] = "office_head";
    AgentSubtypes["Employee"] = "employee";
})(AgentSubtypes || (AgentSubtypes = {}));
export const AgentSubtypesLabel = {
    [AgentSubtypes.CompanyHead]: 'Руководитель агентства',
    [AgentSubtypes.OfficeHead]: 'Руководитель офиса',
    [AgentSubtypes.Employee]: 'Риелтор'
};
