import { BREAKPOINTS, MEDIA } from '@shared/config/constants';
export const getMedia = () => {
    if (matchMedia(`(min-width: ${BREAKPOINTS.DESKTOP}px)`).matches) {
        return MEDIA.DESKTOP;
    }
    if (matchMedia(`(min-width: ${BREAKPOINTS.TABLET}px)`).matches) {
        return MEDIA.TABLET;
    }
    return MEDIA.MOBILE;
};
