import { URL } from '@shared/config/urls';
import { PartnershipLevel } from '../../config/partnershipLevel';
import bronzePNG from './images/bronze.png';
import bronzeWEBP from './images/bronze.webp';
import bronze2xPNG from './images/bronze@2x.png';
import bronze2xWEBP from './images/bronze@2x.webp';
import goldPNG from './images/gold.png';
import goldWEBP from './images/gold.webp';
import gold2xPNG from './images/gold@2x.png';
import gold2xWEBP from './images/gold@2x.webp';
import platinumPNG from './images/platinum.png';
import platinumWEBP from './images/platinum.webp';
import platinum2xPNG from './images/platinum@2x.png';
import platinum2xWEBP from './images/platinum@2x.webp';
import silverPNG from './images/silver.png';
import silverWEBP from './images/silver.webp';
import silver2xPNG from './images/silver@2x.png';
import silver2xWEBP from './images/silver@2x.webp';
export const getPartnershipLevel = (partnerShipLevel) => {
    switch (partnerShipLevel) {
        case PartnershipLevel.Bronze:
            return {
                image: {
                    src: bronzePNG,
                    src2x: bronze2xPNG,
                    webpSrc: bronzeWEBP,
                    webpSrc2x: bronze2xWEBP
                },
                label: 'Бронза',
                tooltip: {
                    text: 'Входит в 10% лучших по региону',
                    href: URL.PROMO_LEADER_AGENCY
                }
            };
        case PartnershipLevel.Silver:
            return {
                image: {
                    src: silverPNG,
                    src2x: silver2xPNG,
                    webpSrc: silverWEBP,
                    webpSrc2x: silver2xWEBP
                },
                label: 'Серебро',
                tooltip: {
                    text: 'Входит в 5% лучших по региону',
                    href: URL.PROMO_LEADER_AGENCY
                }
            };
        case PartnershipLevel.Gold:
            return {
                image: {
                    src: goldPNG,
                    src2x: gold2xPNG,
                    webpSrc: goldWEBP,
                    webpSrc2x: gold2xWEBP
                },
                label: 'Золото',
                tooltip: {
                    text: 'Входит в 1,5% лучших по региону',
                    href: URL.PROMO_LEADER_AGENCY
                }
            };
        case PartnershipLevel.Platinum:
            return {
                image: {
                    src: platinumPNG,
                    src2x: platinum2xPNG,
                    webpSrc: platinumWEBP,
                    webpSrc2x: platinum2xWEBP
                },
                label: 'Платина',
                tooltip: {
                    text: 'Лидер, входит в топ-100 по России',
                    href: URL.PROMO_LEADER_AGENCY
                }
            };
        default:
            return null;
    }
};
