import { Unicode } from '@shared/config/unicode';
const getPluralForm = (number, [one, two, five], withNumber = false) => {
    const floorNumber = Math.floor(number);
    const forms = [one, two, five];
    const lastDigit = floorNumber % 10;
    const twoLastDigits = floorNumber % 100;
    if (withNumber) {
        forms[0] = `${floorNumber}${Unicode.NoBreakSpace}${one}`;
        forms[1] = `${floorNumber}${Unicode.NoBreakSpace}${two}`;
        forms[2] = `${floorNumber}${Unicode.NoBreakSpace}${five}`;
    }
    if (lastDigit === 0 || (lastDigit >= 5 && lastDigit <= 9) || (twoLastDigits >= 11 && twoLastDigits <= 14)) {
        return forms[2];
    }
    if (lastDigit >= 2 && lastDigit <= 4) {
        return forms[1];
    }
    return forms[0];
};
const getInitials = (text) => {
    const words = text.split(' ');
    let initials = '';
    for (const word of words) {
        initials += word.charAt(0).toUpperCase();
    }
    return initials;
};
const addNbsp = (text) => {
    const prepositions = [
        'без',
        'между',
        'под',
        'в',
        'на',
        'по',
        'вокруг',
        'о',
        'про',
        'и',
        'от',
        'с',
        'ооо',
        'пао',
        'за',
        'не',
        'к',
        'до',
        'г',
        'у'
    ];
    if (!text) {
        return '';
    }
    return text.replace(/(\S+) /g, (_char, firstMatch) => firstMatch + (prepositions.includes(firstMatch.toLowerCase()) ? Unicode.NoBreakSpace : ' '));
};
const formatCurrency = (currency) => {
    if (typeof currency === 'number') {
        return currency.toLocaleString('ru-RU');
    }
    return Number(currency).toLocaleString('ru-RU');
};
const isPhoneNumber = (string) => new RegExp(/^\d{11}$/).test(string);
export const StringHelper = {
    getPluralForm,
    getInitials,
    addNbsp,
    isPhoneNumber,
    formatCurrency
};
