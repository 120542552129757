import { combineReducers } from '@reduxjs/toolkit';
import { agenciesSlice } from '@entities/agencies';
import { agentsSlice } from '@entities/agents';
import { csiSlice } from '@entities/csi';
import { dealsSlice } from '@entities/deals';
import { geoSlice } from '@entities/geo';
import { offersSlice } from '@entities/offers';
import { reviewsSlice } from '@entities/reviews';
import { uiSlice } from '@entities/ui';
import { urlSlice } from '@entities/url';
import { userSlice } from '@entities/user';
import { baseApi } from '@shared/api/base';
import { ppsApi } from '@shared/api/pps';
import { researchApi } from '@shared/api/research';
export const rootReducer = combineReducers({
    apiPPS: ppsApi.reducer,
    apiBase: baseApi.reducer,
    apiResearch: researchApi.reducer,
    agents: agentsSlice.reducer,
    agencies: agenciesSlice.reducer,
    offers: offersSlice.reducer,
    deals: dealsSlice.reducer,
    csi: csiSlice.reducer,
    geo: geoSlice.reducer,
    reviews: reviewsSlice.reducer,
    ui: uiSlice.reducer,
    url: urlSlice.reducer,
    user: userSlice.reducer
});
